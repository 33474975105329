import theme from './main';

export default theme;

export enum Colors {
  CARDINAL = 'rgb(140,21,21)', // Stanford Cardinal Red
  COOLGREY = 'rgb(83, 86, 90)', // Stanford Cool Grey
  GOLD = 'rgb(178,111,22, 0.7)', // Stanford Gold, 70% opacity
  GREY = 'rgb(0, 0, 0, 0.54)',
  PURPLE = 'rgb(83,40,79)', // Stanford Purple
  LIGHT_PURPLE = 'rgb(83,40,79, 0.26)', // Stanford Purple, 26% opacity
  SKY_BLUE_LIGHT = 'rgb(103, 175, 210)',
  STANFORD_BRIGHT_RED = 'rgb(177, 4, 14, 0.8)',
  STONE = 'rgb(127, 119, 118)',
  STONE_LIGHT = 'rgb(212,209,209)',
  HYPERLINK = '#006CB8',
}
