/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum ContactMethod {
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  TEXT = "TEXT",
}

/**
 * An enumeration.
 */
export enum GroupRole {
  ADMIN = "ADMIN",
  MEMBER = "MEMBER",
}

/**
 * An enumeration.
 */
export enum ProjectRole {
  ADMIN = "ADMIN",
  EDITOR = "EDITOR",
  VIEWER = "VIEWER",
}

export interface CreateGroupInput {
  name: string;
  contactMethod?: ContactMethod | null;
  contact?: string | null;
  description?: string | null;
  userRoles?: GroupRolesInput | null;
}

export interface CreateProjectInput {
  name: string;
  contactMethod?: ContactMethod | null;
  contact?: string | null;
  description?: string | null;
  isOpen?: boolean | null;
  userRoles?: ProjectRolesInput | null;
  groupRoles?: ProjectRolesInput | null;
  tags?: string[] | null;
}

export interface CreateTagInput {
  name: string;
}

export interface DeleteProjectInput {
  id: string;
}

export interface FileURIInput {
  projectId: string;
  fileName: string;
}

export interface GroupRolesInput {
  admin: string[];
  member: string[];
}

export interface ProjectRolesInput {
  admin: string[];
  editor: string[];
  viewer: string[];
}

export interface RevokeTokenInput {
  token: string;
}

export interface UpdateGroupInput {
  name?: string | null;
  contactMethod?: ContactMethod | null;
  contact?: string | null;
  description?: string | null;
  userRoles?: GroupRolesInput | null;
  id: string;
}

export interface UpdateProjectInput {
  name?: string | null;
  contactMethod?: ContactMethod | null;
  contact?: string | null;
  description?: string | null;
  isOpen?: boolean | null;
  userRoles?: ProjectRolesInput | null;
  groupRoles?: ProjectRolesInput | null;
  tags?: string[] | null;
  id: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
