import gql from 'graphql-tag';

export const groupNames = gql`
  query GroupNames {
    groupNames
  }
`;

export const tags = gql`
  query Tags {
    tags {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const users = gql`
  query Users {
    users {
      edges {
        node {
          id
          username
          displayName
          contactMethod
          contact
        }
      }
    }
  }
`;

export const userData = gql`
  fragment UserData on User {
    id
    username
    displayName
    contactMethod
    contact
  }
`;

export const user = gql`
  query User {
    user {
      ...UserData
    }
  }
  ${userData}
`;

const projectData = gql`
  fragment ProjectData on Project {
    id
    updatedAt
    name
    contactMethod
    contact
    description
    isOpen
    isArchived
    archiveUrl
    userRoles {
      edges {
        node {
          id
          role
          user {
            id
            username
            displayName
            contactMethod
            contact
          }
        }
      }
    }
    groupRoles {
      edges {
        node {
          id
          role
          group {
            id
            name
            contactMethod
            contact
          }
        }
      }
    }
    effectiveUserRoles {
      edges {
        node {
          id
          role
          user {
            id
            username
          }
        }
      }
    }
    tags {
      edges {
        node {
          id
          tag {
            id
            name
          }
        }
      }
    }
    files {
      edges {
        node {
          name
          updatedAt
          tags {
            edges {
              node {
                id
                tag {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const openProjects = gql`
  query OpenProjects {
    openProjects {
      edges {
        node {
          id
          updatedAt
          name
          contactMethod
          contact
          description
          isOpen
          isArchived
          archiveUrl
          tags {
            edges {
              node {
                id
                tag {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

const groupData = gql`
  fragment GroupData on Group {
    id
    updatedAt
    name
    contactMethod
    contact
    description
    userRoles {
      edges {
        node {
          id
          role
          user {
            id
            username
            displayName
            contactMethod
            contact
          }
        }
      }
    }
  }
`;

export const node = gql`
  query Node($id: ID!) {
    node(id: $id) {
      ... on Project {
        ...ProjectData
      }
    }
  }
  ${projectData}
`;

export const effectiveProjectRoles = gql`
  query EffectiveProjectRoles {
    user {
      id
      effectiveProjectRoles {
        edges {
          node {
            id
            role
            project {
              ...ProjectData
            }
          }
        }
      }
    }
  }
  ${projectData}
`;

export const groupRoles = gql`
  query GroupRoles {
    user {
      id
      groupRoles {
        edges {
          node {
            id
            role
            group {
              ...GroupData
            }
          }
        }
      }
    }
  }
  ${groupData}
`;

export const personalTokens = gql`
  query PersonalTokens {
    user {
      id
      personalTokens {
        edges {
          node {
            id
            token
          }
        }
      }
    }
  }
`;

export const createProject = gql`
  mutation CreateProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      err
    }
  }
`;

export const updateProject = gql`
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      ok {
        ...ProjectData
      }
      err
    }
  }
  ${projectData}
`;

export const createTag = gql`
  mutation CreateTag($input: CreateTagInput!) {
    createTag(input: $input) {
      ok
      err
    }
  }
`;

export const createGroup = gql`
  mutation CreateGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      err
    }
  }
`;

export const updateGroup = gql`
  mutation UpdateGroup($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      ok {
        ...GroupData
      }
      err
    }
  }
  ${groupData}
`;

export const createPersonalToken = gql`
  mutation CreatePersonalToken {
    createPersonalToken {
      ok
      err
    }
  }
`;

export const revokePersonalToken = gql`
  mutation RevokePersonalToken($input: RevokeTokenInput!) {
    revokePersonalToken(input: $input) {
      ok
      err
    }
  }
`;

export const createFileUploadURI = gql`
  mutation CreateFileUploadURI($input: FileURIInput!) {
    createFileUploadUri(input: $input) {
      ok {
        name
        uri
      }
      err
    }
  }
`;

export const createFileDownloadURI = gql`
  mutation CreateFileDownloadURI($input: FileURIInput!) {
    createFileDownloadUri(input: $input) {
      ok {
        name
        uri
      }
      err
    }
  }
`;

export const deleteFile = gql`
  mutation DeleteFile($input: FileURIInput!) {
    deleteFile(input: $input) {
      ok
      err
    }
  }
`;

export const deleteProject = gql`
  mutation DeleteProject($input: DeleteProjectInput!) {
    deleteProject(input: $input) {
      ok
      err
    }
  }
`;
