import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon, People as GroupIcon } from '@material-ui/icons';
import { useMutation } from '@apollo/client';
import React, {
  useEffect, useState, FormEvent, ReactElement,
} from 'react';
import { Contact, Field } from '..';
import * as Q from '../../gql';
import * as T from '../../types';
import * as U from '../../utils';
import { Colors } from '../../styles';
// based on this example: https://tinyurl.com/y2qa9oku
const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  dialog: {
    padding: theme.spacing(2),
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 2, 0, 2),
  },
  groupIcon: {
    color: Colors.GOLD,
    margin: theme.spacing(0, 2, 0, 0),
  },
  submit: {
    margin: theme.spacing(0, 0, 2, 0),
    textTransform: 'none',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface Props {
  open: boolean;
  userData: T.UserData | null | undefined;
  groupNames: string[];
  setError: T.SetState<string | undefined>;
  onClose: () => void;
}

export default function GroupCreator(props: Props): ReactElement {
  const {
    open, userData, groupNames, onClose, setError,
  } = props;
  const classes = useStyles();
  const [input, setInput] = useState<T.CreateGroupInput>({
    name: '',
    contactMethod: T.ContactMethod.EMAIL,
    contact: '',
    description: '',
    userRoles: {
      admin: [],
      member: [],
    },
  });
  const [createGroup] = useMutation<T.CreateGroup>(Q.createGroup, {
    onError: (e) => U.checkApolloError(e, setError),
    onCompleted({ createGroup: res }) {
      U.checkMutationError(res, setError);
    },
    refetchQueries: [{ query: Q.groupRoles }, { query: Q.groupNames }],
  });
  const helperText = 'Only letters, numbers and underscores.';

  function reset(): void {
    const resetInput: T.CreateGroupInput = {
      name: '',
      contactMethod: T.ContactMethod.EMAIL,
      contact: '',
      description: '',
      userRoles: {
        admin: [],
        member: [],
      },
    };
    if (userData?.username) {
      resetInput.userRoles!.admin = [userData.username];
      resetInput.contactMethod = userData.contactMethod;
      resetInput.contact = userData.contact;
    }
    setInput(resetInput);
  }

  useEffect(() => {
    reset();
  }, [userData]);

  let nameLabel = U.nameLabel(
    'Group',
    input.name,
    U.isValidShortName,
    'Only letters, numbers and underscores',
  );

  const nameIsTaken = groupNames.includes(input.name);

  if (nameIsTaken) {
    nameLabel = 'Name already taken';
  }

  const isValidGroupName = U.isValidShortName(input.name) && !nameIsTaken;
  const canSave = isValidGroupName
    && U.isValidContact(input.contactMethod!, input.contact!)
    && input.userRoles!.admin.length > 0;

  function onSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    createGroup({ variables: { input } });
    onClose();
    reset();
  }

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle disableTypography className={classes.title}>
        <GroupIcon className={classes.groupIcon} fontSize="large" />
        <Typography variant="h6">Create Group</Typography>
        <IconButton onClick={onClose} className={classes.closeIcon}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <form noValidate onSubmit={onSubmit} className={classes.form}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Field
                name="groupName"
                label={nameLabel}
                value={input.name}
                required
                setValue={(name): void => setInput((v) => ({ ...v, name }))}
                isValid={(): boolean => isValidGroupName}
                helperText={helperText}
              />
            </Grid>
            <Grid item>
              <Contact
                contactMethod={input.contactMethod!}
                setContactMethod={(contactMethod: T.ContactMethod): void => {
                  setInput((v) => ({ ...v, contactMethod }));
                }}
                contact={input.contact!}
                setContact={(contact: string): void => {
                  setInput((v) => ({ ...v, contact }));
                }}
              />
            </Grid>
            <Grid item>
              <Field
                name="description"
                label="Description"
                value={input.description || ''}
                setValue={(description): void => setInput((v) => {
                  const i = { ...v };
                  i.description = description;
                  return i;
                })}
                isValid={(): boolean => true}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                fullWidth
                className={classes.submit}
                disabled={!canSave}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
