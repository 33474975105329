import {
  Card as CardComponent,
  CardActionArea,
  CardContent,
  Theme,
  Typography,
} from '@material-ui/core';
import { AddBox as NewIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    width: 250,
  },
  cardAction: {
    height: '100%',
  },
  cardContent: {
    alignItems: 'center',
    color: 'rgb(0,0,0,0.5)',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    height: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minHeight: 200,
  },
  icon: {
    fontSize: 60,
  },
}));

interface Props {
  label: string;
  onClick: () => void;
}

export default function CreateCard(props: Props): ReactElement {
  const { label, onClick } = props;
  const classes = useStyles();
  return (
    <CardComponent className={classes.card}>
      <CardActionArea onClick={onClick} className={classes.cardAction}>
        <CardContent className={classes.cardContent}>
          <NewIcon fontSize="inherit" className={classes.icon} />
          <Typography gutterBottom variant="h6" align="center">
            {`Create New ${label}`}
          </Typography>
        </CardContent>
      </CardActionArea>
    </CardComponent>
  );
}
