import { TextField, Tooltip } from '@material-ui/core';
import React, { ReactElement } from 'react';

interface Props {
  name: string;
  label: string;
  value: string;
  setValue: (v: string) => void;
  isValid: (v: string) => boolean;
  disabled?: boolean;
  required?: boolean;
  tooltipText?: string;
  multiline?: boolean;
  helperText?: string;
  rows?: number;
}

export default function Field(props: Props): ReactElement {
  const {
    name,
    label,
    value,
    setValue,
    isValid,
    disabled,
    required,
    tooltipText,
    multiline,
    helperText,
    rows,
  } = props;

  const valid = isValid(value) || value === '';

  let jsx = (
    <TextField
      disabled={disabled}
      required={required}
      fullWidth
      error={!valid}
      autoComplete="off"
      id={name}
      label={label}
      name={name}
      value={value}
      onChange={(event): void => setValue(event.target.value)}
      multiline={multiline}
      helperText={helperText}
      rows={rows || 1}
      variant="outlined"
    />
  );

  if (tooltipText) {
    jsx = (
      <Tooltip disableFocusListener title={tooltipText}>
        {jsx}
      </Tooltip>
    );
  }

  return jsx;
}
