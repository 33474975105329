import { Dispatch, SetStateAction } from 'react';

export type { CreateFileDownloadURI } from './gen/CreateFileDownloadURI';
export type { CreateFileUploadURI } from './gen/CreateFileUploadURI';
export type { CreateGroup } from './gen/CreateGroup';
export type { CreateProject } from './gen/CreateProject';
export type { CreateTag } from './gen/CreateTag';
export type {
  EffectiveProjectRoles,
  EffectiveProjectRoles_user_effectiveProjectRoles_edges as ProjectEdge,
  // eslint-disable-next-line max-len
  EffectiveProjectRoles_user_effectiveProjectRoles_edges_node_project_files_edges_node as File,
} from './gen/EffectiveProjectRoles';
export type { GroupData } from './gen/GroupData';
export type { GroupNames } from './gen/GroupNames';
export {
  ContactMethod,
  GroupRole,
  ProjectRole,
} from './gen/globalTypes';
export type {
  CreateGroupInput,
  CreateProjectInput,
  CreateTagInput,
  DeleteProjectInput,
  FileURIInput,
  GroupRolesInput,
  ProjectRolesInput,
  RevokeTokenInput,
  UpdateGroupInput,
  UpdateProjectInput,
} from './gen/globalTypes';
export type { GroupRoles } from './gen/GroupRoles';
export type { NamesData } from './gen/NamesData';
export type { Node, Node_node_Project as ProjectNode } from './gen/Node';
export type {
  OpenProjects,
  OpenProjects_openProjects_edges as OpenProjectEdge,
  OpenProjects_openProjects_edges_node as OpenProjectData,
} from './gen/OpenProjects';
export type { PersonalTokens } from './gen/PersonalTokens';
export type {
  ProjectData,
  ProjectData_effectiveUserRoles_edges_node as ProjectEffectiveUserRoleNode,
} from './gen/ProjectData';
export type { UpdateGroup } from './gen/UpdateGroup';
export type { UpdateProject } from './gen/UpdateProject';
export type { DeleteProject } from './gen/DeleteProject';
export type { Tags, Tags_tags_edges_node as Tag } from './gen/Tags';
export type { User } from './gen/User';
export type { UserData } from './gen/UserData';
export type { Users } from './gen/Users';

// types
export type SetState<T> = Dispatch<SetStateAction<T>>;

// enums
export enum EntityType {
  GROUP = 'group',
  PROJECT = 'project',
}

export enum TrackingAction {
  USE_PLUGIN = 'use_plugin',
}

export enum TrackingCategory {
  PLUGIN = 'plugin',
}

export enum Route {
  ABOUT = '/about',
  AFFILIATES = '/affiliates',
  API_CONSOLE = '/api_console',
  DATA_ARCHIVE = '/archive',
  LOGIN = '/login',
  MANAGE_KEYS = '/manage_keys',
  MY_GROUPS = '/groups',
  MY_PROJECTS = '/',
  OPEN_PROJECTS = '/open_projects',
}

// interfaces
export interface Nameable {
  firstName: string;
  lastName: string;
}
