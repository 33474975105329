import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React, { ReactElement } from 'react';

interface Props {
  error: string | undefined;
  clear: () => void;
}

export default function AlertDialog(props: Props): ReactElement {
  const { error, clear } = props;
  return (
    <Dialog
      open={Boolean(error)}
      maxWidth="xs"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      transitionDuration={{ exit: 0 }}
    >
      <DialogTitle id="alert-dialog-title">Big Local News</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {error}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={(): void => clear()} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
