import {
  Grid,
  LinearProgress,
  ListItem,
  ListItemText,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  text: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(7),
  },
  progress: {
    flexGrow: 1,
    paddingRight: theme.spacing(2),
  },
}));

export interface Props {
  fileName: string;
  value: number; // takes a number between 0..100
}

export default function FilesItemUploading(props: Props): ReactElement {
  const { fileName, value } = props;
  const classes = useStyles();
  return (
    <ListItem key={fileName} divider className={classes.item}>
      <Grid container alignItems="center" direction="row">
        <Grid item>
          <ListItemText primary={fileName} className={classes.text} />
        </Grid>
        <Grid item className={classes.progress}>
          {value < 100 ? (
            <LinearProgress
              variant="determinate"
              value={value}
              color="primary"
            />
          ) : (
            <LinearProgress color="primary" />
          )}
        </Grid>
      </Grid>
    </ListItem>
  );
}
