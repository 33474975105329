import {
  Card as CardComponent,
  CardActionArea,
  CardContent,
  Grid,
  Theme,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import {
  InsertDriveFile as FileIcon,
  Public as OpenProjectIcon,
  Person as UserIcon,
  LocalLibrary as ArchiveIcon,
  Lock as MyProjectIcon
} from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React, { ReactElement } from "react";
import * as T from "../../types";
import * as U from "../../utils";
import { TagsDisplay } from "..";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    height: "auto",
    maxWidth: theme.breakpoints.values.md,
    boxShadow: "0 3px 6px rgba(0, 0, 0, 13%), 0 3px 6px rgba(0, 0, 0, 10%)",
    borderRadius: "2px",
    background: "white",
    margin: "0.25em 0.25em 1.5em 0.25em",
    "&:hover": {
      boxShadow: "0px 3px 6px rgba(0, 0, 0, 26%), 0 3px 6px rgba(0, 0, 0, 20%)"
    }
  },
  cardContent: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    padding: "19.8px 19.8px 9.9px 19.8px",
    "&:last-child": {
      paddingBottom: theme.spacing(1)
    },
    marginBottom: theme.spacing(1)
  },
  description: {
    marginBottom: 2,
    paddingTop: 10,
    fontSize: 16,
    color: theme.palette.grey[700]
  },
  focusHighlight: {
    backgroundColor: "transparent"
  },
  hedWrapper: {
    color: theme.palette.common.black
  },
  hed: {
    flexGrow: 1,
    paddingRight: theme.spacing(1)
  },
  projectName: {
    fontWeight: 700
  },
  icon: {
    flexGrow: 0,
    marginRight: theme.spacing(0.75),
    paddingTop: theme.spacing(0)
  },
  role: {
    flexGrow: 0,
    marginRight: theme.spacing(0.75)
  },
  roleText: {
    textTransform: "capitalize"
  },
  subhedIcon: {
    marginRight: 3
  },
  subhedLeft: {
    flexGrow: 1,
    flexBasis: "50%"
  },
  subhedText: {},
  subhedItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 0,
    marginRight: "4px"
  },
  subhedWrapper: {
    display: "flex",
    flexDirection: "row",
    color: theme.palette.grey[700],
    margin: 0,
    padding: 0
  },
  tags: {},
  tagsWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  updatedAt: {
    color: theme.palette.grey[700],
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
}));

interface Props {
  project: T.ProjectData | T.OpenProjectData;
  role: T.ProjectRole;
  tags: T.Tag[];
  onClick: () => void;
  setError: T.SetState<string | undefined>;
}

export default function ProjectCard(props: Props): ReactElement {
  const {
    project: { description, isOpen, name },
    project,
    role,
    tags,
    onClick,
    setError
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const mobile = !useMediaQuery(theme.breakpoints.up("sm"));
  const isArchived = project!.isArchived && project!.archiveUrl;

  let numUsers = 0;
  let numFiles = 0;

  if (U.isMyProject(project)) {
    numUsers = project.effectiveUserRoles?.edges.length || 0;
    numFiles = project?.files?.edges.length || 0;
  }

  let desc = description;
  if (desc && (desc.length > 225 || desc.length === 0)) {
    desc = `${desc.slice(0, 225).trim()}...`;
  }

  let projectName = name;

  if (mobile && name.length >= 20) {
    projectName = `${name.slice(0, 20)}...`;
  }

  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate()
      && date.getMonth() === today.getMonth()
      && date.getFullYear() === today.getFullYear()
    );
  };

  const isThisYear = (date) => {
    const today = new Date();
    return date.getFullYear() === today.getFullYear();
  };

  const formatTimestamp = (timestamp: string): string => {
    const parser = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric"
    });

    const dateObject = new Date(timestamp);
    const [
      { value: month },,
      { value: day },,
      { value: year },,
      { value: hour },,
      { value: minute },,
      { value: dayPeriod }
    ] = parser.formatToParts(dateObject);

    if (isToday(dateObject)) {
      return `${hour}:${minute} ${dayPeriod}`;
    }
    if (isThisYear(dateObject)) {
      return `${month} ${day}`;
    }
    return `${month} ${day}, ${year}`;
  };

  return (
    <CardComponent raised elevation={2} className={classes.card}>
      <CardActionArea
        disableRipple
        disableTouchRipple
        onClick={onClick}
        classes={{
          focusHighlight: classes.focusHighlight
        }}
      >
        <CardContent classes={{ root: classes.cardContent }}>
          <Grid container>
            <Grid
              item
              container
              className={classes.hedWrapper}
              alignItems="center"
              direction="row"
            >
              {isOpen && !isArchived && (
                <Grid item className={classes.icon}>
                  <OpenProjectIcon color="inherit" />
                </Grid>
              )}
              {isOpen && isArchived && (
                <Grid item className={classes.icon}>
                  <ArchiveIcon color="inherit" />
                </Grid>
              )}
              {!isOpen && (
                <Grid item className={classes.icon}>
                  <MyProjectIcon color="inherit" />
                </Grid>
              )}
              <Grid item className={classes.hed}>
                <Typography
                  variant="h6"
                  display="block"
                  noWrap
                  color="inherit"
                  className={classes.projectName}
                >
                  {projectName}
                </Typography>
              </Grid>
              <Grid item className={classes.subhedWrapper}>
                <Grid item container className={classes.subhedLeft}>
                  {role !== T.ProjectRole.VIEWER && (
                    <Grid item className={classes.role}>
                      <Typography
                        variant="subtitle2"
                        align="right"
                        color="inherit"
                        className={classes.roleText}
                      >
                        {role.toLowerCase()}
                      </Typography>
                    </Grid>
                  )}
                  {!isArchived && numFiles > 0 && (
                    <Grid item className={classes.subhedItem}>
                      <FileIcon
                        fontSize="small"
                        className={classes.subhedIcon}
                      />
                      <Typography variant="caption">{`${numFiles}`}</Typography>
                    </Grid>
                  )}
                  {numUsers > 0 && (
                    <Grid item className={classes.subhedItem}>
                      <UserIcon
                        fontSize="small"
                        className={classes.subhedIcon}
                      />
                      <Typography variant="caption">{`${numUsers}`}</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography className={classes.description}>{desc}</Typography>
            </Grid>
            <Grid item container className={classes.tagsWrapper}>
              <Grid item xs={8} className={classes.tags}>
                <TagsDisplay
                  tags={tags}
                  setError={setError}
                  project={project}
                  role={role}
                />
              </Grid>
              <Grid item className={classes.updatedAt} xs={4}>
                <Typography variant="caption" align="right" display="block">
                  {`Edited ${formatTimestamp(project.updatedAt)}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </CardComponent>
  );
}
