import {
  AppBar,
  Button,
  Grid,
  Tooltip,
  IconButton,
  Theme,
  Typography,
  Toolbar,
  useMediaQuery,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { AddCircle as CreateNewIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React, { useEffect, useState, ReactElement } from 'react';
import {
  AlertDialog,
  Search,
  TagsFilter,
  ProjectCard,
  ProjectCreator,
} from '..';
import * as Q from '../../gql';
import * as T from '../../types';
import * as U from '../../utils';
import { Colors } from '../../styles';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: 0,
    marginLeft: '0.25em',
  },
  button: {
    padding: 0,
    textTransform: 'none',
    fontWeight: 550,
  },
  buttonWrapper: {
    alignSelf: 'self-end',
    flexGrow: 0,
  },
  cardsWrapper: {
    overflow: 'hidden',
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: "50px",
    },
  },
  createWrapper: {
    flexGrow: 0,
  },
  hed: {
    flexGrow: 1,
  },
  main: {
    maxWidth: theme.breakpoints.values.md,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      minWidth: theme.breakpoints.values.md,
    },
  },
  mobile: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mobileCreate: {
    paddingTop: theme.spacing(1),
  },
  projectCount: {
    fontWeight: 400,
    color: theme.palette.grey[700],
  },
  projectCountWrapper: {
    flexGrow: 1,
    marginLeft: '0.25em',
  },
  search: {
    position: 'relative',
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      width: 'auto',
    },
  },
  searchInfo: {
    paddingBottom: theme.spacing(1),
  },
  searchWrapper: {
    maxWidth: theme.breakpoints.values.md,
    position: 'sticky',
    [theme.breakpoints.up('sm')]: {
      top: 0,
    },
    top: 55,
    backgroundColor: theme.palette.common.white,
    zIndex: 1000,
  },
  title: {
    display: 'none',
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  titleMobile: {
    fontWeight: 700,
    paddingTop: theme.spacing(1),
  },
  createButton: {
    color: Colors.HYPERLINK,
  },
}));

export default function MyProjects(): ReactElement {
  const history = useHistory();

  // Boot up the CSS stuff
  const classes = useStyles();
  const theme = useTheme();

  // Setup reactive variables
  const [error, setError] = useState<string | undefined>(undefined);
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [openCreator, setOpenCreator] = useState<boolean>(false);

  // Query data from the database
  const { data: qUser } = U.useQ<T.User>(Q.user, setError);
  const { data: qTags } = U.useQ<T.Tags>(Q.tags, setError);
  const { data: qRoles } = U.useQ<T.EffectiveProjectRoles>(
    Q.effectiveProjectRoles,
    setError,
  );

  // Pull out how many projects are here
  const edges = qRoles?.user?.effectiveProjectRoles?.edges || [];

  // Pull out the tags
  const tags = qTags?.tags?.edges.map((e) => e!.node!) || [];

  // Are we on a mobile phone?
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

  // Is the data cached?
  const isCached = U.useIsCached<T.EffectiveProjectRoles>(
    Q.effectiveProjectRoles,
  );

  // Initialize a button to create projects
  const CreateButton = (): ReactElement => (
    <Tooltip arrow title="Create new project">
      <IconButton
        onClick={(): void => {
          setOpenCreator(true);
        }}
        className={classes.createButton}
      >
        <CreateNewIcon fontSize="large" />
      </IconButton>
    </Tooltip>
  );

  // Get the tags that currently selected after reactive inputs do their thing
  const getTagNames = (proj: T.ProjectEdge): string[] | [] => {
    if (!proj) {
      return [];
    }
    const tagNames = proj!.node!.project!.tags!.edges.map(
      (edge) => edge!.node!.tag!.name,
    );
    return tagNames;
  };

  // Is the page currently being filtered by a search?
  const isFiltered = searchResults.length > 0 || searchInput !== '';

  // Are there any results on the page after all the filters and what not?
  const noResults = searchResults.length === 0 && searchInput !== '';

  // The fields we will search against with our input
  const keys = [
    'node.project.name',
    'node.project.description',
    'node.project.tags.edges.node.tag.name',
  ];

  // The height of dummy elements on the page as things are loading
  const skeletonHeight = 185;

  // If the user isn't logged in, redirect to the login page.
  useEffect(() => {
    if (!U.isLoggedIn()) {
      history.push(T.Route.LOGIN);
    }
  });

  return (
    <div className={classes.main}>
      <AlertDialog error={error} clear={(): void => setError(undefined)} />
      <div className={classes.searchWrapper}>
        <div>
          <AppBar position="static" elevation={0} className={classes.appBar}>
            {isMobile && (
              <div className={classes.mobile}>
                <Typography variant="h5" className={classes.titleMobile}>
                  My Projects
                </Typography>
                <div className={classes.mobileCreate}>
                  <CreateButton />
                </div>
              </div>
            )}
            <Toolbar disableGutters>
              <Typography variant="h5" className={classes.title}>
                My Projects
              </Typography>
              <div className={classes.search}>
                <Search
                  projects={edges}
                  setSearchResults={setSearchResults}
                  searchInput={searchInput}
                  setSearchInput={setSearchInput}
                  searchKeys={keys}
                />
              </div>
              {!isMobile && (
                <div className={classes.createWrapper}>
                  <CreateButton />
                </div>
              )}
            </Toolbar>
          </AppBar>
        </div>
        <div>
          <TagsFilter
            projects={edges}
            searchResults={searchResults}
            setSearchResults={setSearchResults}
            noResults={noResults}
            getTagNames={getTagNames}
          />
        </div>
        <Grid
          container
          className={classes.searchInfo}
          alignItems="center"
          direction="row"
        >
          <Grid item className={classes.projectCountWrapper}>
            <Typography variant="body2" className={classes.projectCount}>
              {!isCached
                ? 'Loading projects...'
                : `Displaying ${
                  isFiltered ? searchResults.length : edges.length
                } of ${edges.length} projects`}
            </Typography>
          </Grid>
          {isFiltered && (
          <Grid item className={classes.buttonWrapper}>
            <Button
              disabled={!isFiltered}
              color="primary"
              onClick={(): void => {
                setSearchInput('');
                setSearchResults([]);
              }}
              className={classes.button}
            >
              Show All
            </Button>
          </Grid>
          )}
        </Grid>
      </div>
      <div className={classes.cardsWrapper}>
        {noResults ? (
          <Typography variant="subtitle1">
            {`No results found for "${searchInput}."`}
          </Typography>
        ) : (
          <Grid
            container
            justifyContent="flex-start"
            direction="column"
            spacing={4}
          >
            {!isCached
              ? [1, 2, 3, 4].map((i) => (
                <Grid item key={i}>
                  <Skeleton variant="rect" height={skeletonHeight} />
                </Grid>
              ))
              : edges
                .filter((e) => (searchResults.length > 0
                  ? searchResults.includes(e!.node!.id)
                  : true))
                .sort(
                  (a, b) => Date.parse(b!.node!.project!.updatedAt)
                      - Date.parse(a!.node!.project!.updatedAt),
                )
                .map((e) => (
                  <Grid item key={e!.node!.id}>
                    <ProjectCard
                      project={e!.node!.project!}
                      role={e!.node!.role!}
                      tags={tags}
                      onClick={(): void => {
                        history.push(`project/${e!.node!.project!.id}`);
                      }}
                      setError={setError}
                    />
                  </Grid>
                ))}
          </Grid>
        )}
        <ProjectCreator
          open={openCreator}
          userData={qUser?.user}
          setError={setError}
          onClose={(): void => setOpenCreator(false)}
        />
      </div>
    </div>
  );
}
