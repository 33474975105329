import { Grid, TextField, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import React, { useState, ReactElement } from 'react';
import {
  AlertDialog,
  Card,
  CreateCard,
  GroupCreator,
  GroupEditor,
  TitleSection,
} from '..';
import * as Q from '../../gql';
import * as T from '../../types';
import * as U from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
    marginTop: theme.spacing(7),
  },
  content: {
    margin: theme.spacing(0, 4, 0, 4),
  },
}));

export default function MyGroups(): ReactElement {
  const history = useHistory();
  const classes = useStyles();
  if (!U.isLoggedIn()) {
    history.push(T.Route.LOGIN);
  }
  const [search, setSearch] = useState<string>('');
  const [openCreator, setOpenCreator] = useState<boolean>(false);
  const [openEditor, setOpenEditor] = useState<boolean>(false);
  const [group, setGroup] = useState<T.GroupData | null>(null);
  const [role, setRole] = useState<T.GroupRole>(T.GroupRole.MEMBER);
  const [error, setError] = useState<string | undefined>(undefined);
  const { data: qUsers } = U.useQ<T.Users>(Q.users, setError);
  const { data: qGroupNames } = U.useQ<T.GroupNames>(Q.groupNames, setError);
  const { data: qUser } = U.useQ<T.User>(Q.user, setError);
  const { data: qRoles } = U.useQ<T.GroupRoles>(Q.groupRoles, setError);
  const edges = qRoles?.user?.groupRoles?.edges || [];

  const users = qUsers?.users?.edges.map((e) => e?.node).map((d) => ({
    id: d!.id,
    username: d!.username,
    displayName: d!.displayName
  }));

  const groups = qGroupNames?.groupNames?.map((d) => ({
    id: d,
    username: d,
    displayName: d,
    name: d
  }));

  return (
    <Grid container className={classes.main}>
      <AlertDialog error={error} clear={(): void => setError(undefined)} />
      <Grid
        container
        spacing={4}
        direction="column"
        className={classes.content}
      >
        <Grid item>
          <TitleSection
            label="My Groups"
            description="Create groups to more easily manage a collection of
            users. Groups have at least one admin who can add and
            remove members to the group."
          />
        </Grid>
        <Grid item component="form">
          <TextField
            id="search"
            label="Search"
            value={search}
            fullWidth
            onChange={(event): void => setSearch(event.target.value)}
            variant="outlined"
            autoComplete="off"
          />
        </Grid>
        <Grid item>
          <Grid
            container
            justifyContent="flex-start"
            direction="row"
            spacing={4}
          >
            <Grid item>
              <CreateCard
                label="Group"
                onClick={(): void => {
                  setGroup(null);
                  setRole(T.GroupRole.ADMIN);
                  setOpenCreator(true);
                }}
              />
            </Grid>
            {edges
              .filter((e) => e!.node!.group!.name!.includes(search))
              .map((e) => (
                <Grid item key={e!.node!.id}>
                  <Card
                    card={e!.node!.group!}
                    role={e!.node!.role!}
                    onClick={(): void => {
                      setGroup(e!.node!.group);
                      setRole(e!.node!.role);
                      setOpenEditor(true);
                    }}
                  />
                </Grid>
              ))}
          </Grid>
          <GroupCreator
            open={openCreator}
            userData={qUser?.user}
            groupNames={qGroupNames?.groupNames || []}
            setError={setError}
            onClose={(): void => setOpenCreator(false)}
          />
          <GroupEditor
            open={openEditor}
            group={group}
            groupRole={role}
            users={users || []}
            groups={groups || []}
            setError={setError}
            onClose={(): void => setOpenEditor(false)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
