import {
  Grid, List, TextField, Theme,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import React, { ChangeEvent, ReactElement } from 'react';
import RolesItem from '../RolesItem';
import * as T from '../../types';

interface Props {
  title: string;
  disabled: boolean;
  names: any[];
  roles: T.ProjectRolesInput;
  setRoles: (roles: T.ProjectRolesInput) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  autocomplete: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: theme.spacing(4),
  },
  root: {
    display: 'flex',
  },
  list: {
    overflow: 'auto',
    minHeight: 300,
  },
  listWrapper: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
}));

export default function Roles(props: Props): ReactElement {
  const classes = useStyles();
  const {
    title, disabled, names, roles, setRoles,
  } = props;
  const roleOptions = Object.values(T.ProjectRole).map((r) => r.toLowerCase());
  const defaultRole = T.ProjectRole.VIEWER.toLowerCase();

  function remove(obj): T.ProjectRolesInput {
    return {
      admin: roles.admin.filter((n: any) => n.id !== obj.id),
      editor: roles.editor.filter((n: any) => n.id !== obj.id),
      viewer: roles.viewer.filter((n: any) => n.id !== obj.id),
    };
  }

  function setRole(obj, role: string): void {
    const newRoles = remove(obj);
    (newRoles as any)[role].push(obj);
    setRoles(newRoles);
  }

  function onDelete(obj): void {
    setRoles(remove(obj));
  }

  function unusedNames(): any[] {
    return names.filter(
      (n) => !roles.admin.includes(n)
        && !roles.editor.includes(n)
        && !roles.viewer.includes(n),
    );
  }

  // Get the label for the object in the pulldown and list
  function getLabel(d) {
    return ((d!.contact) ? `${d!.displayName || d!.username || d!.name} (${d!.contact})` : d!.displayName) || d!.username || d!.name;
  }

  /* eslint react/jsx-props-no-spreading: 0 */
  return (
    <Grid direction="column" container className={classes.root}>
      {!disabled && (
        <Grid item className={classes.autocomplete}>
          <Autocomplete
            autoHighlight
            options={unusedNames().sort((a, b) => getLabel(a).localeCompare(getLabel(b)))}
            getOptionLabel={(d) => getLabel(d)}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(
              _: ChangeEvent<Record<string, unknown>>,
              value,
            ): void => {
              if (value) {
                setRole(value, defaultRole);
              }
            }}
            renderInput={(params): ReactElement => (
              <TextField
                {...params}
                label={title}
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      )}
      <Grid item className={classes.listWrapper}>
        <List className={classes.list}>
          {roles.admin.sort((a, b) => getLabel(a).localeCompare(getLabel(b))).map((obj: any) => (
            <RolesItem
              disabled={disabled}
              key={obj.id}
              name={getLabel(obj)}
              role={T.ProjectRole.ADMIN.toLowerCase()}
              roleOptions={roleOptions}
              setRole={(role): void => setRole(obj, role)}
              onDelete={(): void => onDelete(obj)}
            />
          ))}
          {roles.editor.sort((a, b) => getLabel(a).localeCompare(getLabel(b))).map((obj: any) => (
            <RolesItem
              disabled={disabled}
              key={obj.id}
              name={getLabel(obj)}
              role={T.ProjectRole.EDITOR.toLowerCase()}
              roleOptions={roleOptions}
              setRole={(role): void => setRole(obj, role)}
              onDelete={(): void => onDelete(obj)}
            />
          ))}
          {roles.viewer.sort((a, b) => getLabel(a).localeCompare(getLabel(b))).map((obj: any) => (
            <RolesItem
              disabled={disabled}
              key={obj.id}
              name={getLabel(obj)}
              role={T.ProjectRole.VIEWER.toLowerCase()}
              roleOptions={roleOptions}
              setRole={(role): void => setRole(obj, role)}
              onDelete={(): void => onDelete(obj)}
            />
          ))}
        </List>
      </Grid>
    </Grid>
  );
}
