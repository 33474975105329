import {
  Grid, List, TextField, Theme, Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import React, { ChangeEvent, ReactElement } from 'react';
import RolesItem from '../RolesItem';
import * as T from '../../types';

interface Props {
  disabled: boolean;
  names: any[];
  roles: T.GroupRolesInput;
  setRoles: (roles: T.GroupRolesInput) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  autocomplete: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: theme.spacing(2),
  },
  list: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    height: 300,
    overflow: 'auto',
  },
  listWrapper: {
    marginTop: theme.spacing(2),
    border: '1px solid rgb(0,0,0,0.23)',
  },
  root: {
    display: 'flex',
  },
}));

export default function Roles(props: Props): ReactElement {
  const classes = useStyles();
  const {
    disabled, names, roles, setRoles,
  } = props;
  const roleOptions = Object.values(T.GroupRole).map((r) => r.toLowerCase());
  const defaultRole = T.GroupRole.MEMBER.toLowerCase();

  function remove(obj): T.GroupRolesInput {
    return {
      admin: roles.admin.filter((n: any) => n.id !== obj.id),
      member: roles.member.filter((n: any) => n.id !== obj.id),
    };
  }

  function setRole(obj, role: string): void {
    const newRoles = remove(obj);
    (newRoles as any)[role].push(obj);
    setRoles(newRoles);
  }

  function onDelete(name: string): void {
    setRoles(remove(name));
  }

  function unusedNames(): any[] {
    return names.filter(
      (n) => !Object.keys(roles.admin).includes(n)
        && !Object.keys(roles.member).includes(n),
    );
  }

  // Get the label for the object in the pulldown and list
  function getLabel(d) {
    return ((d!.contact) ? `${d!.displayName || d!.username || d!.name} (${d!.contact})` : d!.displayName) || d!.username || d!.name;
  }

  /* eslint react/jsx-props-no-spreading: 0 */
  return (
    <Grid direction="column" container className={classes.root}>
      {disabled ? (
        <Typography align="center" variant="h6">
          Users
        </Typography>
      ) : (
        <Grid item className={classes.autocomplete}>
          <Autocomplete
            autoHighlight
            options={unusedNames().sort((a, b) => getLabel(a).localeCompare(getLabel(b)))}
            getOptionLabel={(option) => getLabel(option)}
            onChange={(
              _: ChangeEvent<Record<string, unknown>>,
              value,
            ): void => {
              if (value) {
                setRole(value, defaultRole);
              }
            }}
            renderInput={(params): ReactElement => (
              <TextField
                {...params}
                label="Add User"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      )}
      <Grid item className={classes.listWrapper}>
        <List className={classes.list}>
          {roles.admin.map((obj: any) => (
            <RolesItem
              disabled={disabled}
              key={getLabel(obj)}
              name={getLabel(obj)}
              role={T.GroupRole.ADMIN.toLowerCase()}
              roleOptions={roleOptions}
              setRole={(role): void => setRole(obj, role)}
              onDelete={(): void => onDelete(obj)}
            />
          ))}
          {roles.member.map((obj: any) => (
            <RolesItem
              disabled={disabled}
              key={getLabel(obj)}
              name={getLabel(obj)}
              role={T.GroupRole.MEMBER.toLowerCase()}
              roleOptions={roleOptions}
              setRole={(role): void => setRole(obj, role)}
              onDelete={(): void => onDelete(obj)}
            />
          ))}
        </List>
      </Grid>
    </Grid>
  );
}
