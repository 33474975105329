import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  LocalLibrary as ArchiveIcon,
  Public as OpenIcon,
  Code as DevelopIcon,
  RemoveCircle as DeleteIcon,
} from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import React, { ReactElement, useState } from 'react';
import { Colors } from '../../styles';

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    paddingBottom: theme.spacing(1),
  },
  link: {
    color: theme.palette.secondary.light,
  },
  secondaryText: {
    color: Colors.GREY,
  },
  warning: {
    color: Colors.STANFORD_BRIGHT_RED,
  },
  warningButton: {
    textTransform: 'none',
    color: Colors.STANFORD_BRIGHT_RED,
    borderColor: Colors.STANFORD_BRIGHT_RED,
  },
  cancelButton: {
    textTransform: 'none',
  },
  deleteButton: {
    textTransform: 'none',
    color: Colors.STANFORD_BRIGHT_RED,
  },
}));

interface Props {
  isOpen: boolean;
  projectName: string;
  handleOpenToggle: () => void;
  deleteProject: () => void;
}
export default function ProjectManager(props: Props): ReactElement {
  const {
    isOpen, projectName, handleOpenToggle, deleteProject,
  } = props;
  const { pathname } = useLocation();
  const projectId = pathname.split('/').pop();
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [confirmationValue, setConfirmationValue] = useState<string>('');
  const classes = useStyles();

  const canDelete = confirmationValue === projectName;

  function cancelDelete(): void {
    setConfirmationValue('');
    setOpenConfirmation(false);
  }

  return (
    <>
      <List>
        <ListItem divider>
          <ListItemIcon>
            <OpenIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText
            primary="Open project to all users"
            secondary={(
              <Typography variant="body2" className={classes.secondaryText}>
                This will make your data available to all users, but not the
                general public.
              </Typography>
            )}
          />
          <ListItemSecondaryAction>
            <Switch
              color="primary"
              checked={isOpen}
              onChange={handleOpenToggle}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem divider>
          <ListItemIcon>
            <ArchiveIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText
            primary="Archive project with the Stanford Digital Repository"
            secondary={(
              <Typography variant="body2" className={classes.secondaryText}>
                {'Please send an e-mail to '}
                <Link
                  href="mailto:big-local-sdr@lists.stanford.edu"
                  className={classes.link}
                >
                  big-local-sdr@lists.stanford.edu
                </Link>
                {" to archive your project's data."}
              </Typography>
            )}
          />
        </ListItem>
        <ListItem divider>
          <ListItemIcon>
            <DevelopIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText
            primary="Access via our API"
            secondary={(
              <Typography variant="body2" className={classes.secondaryText}>
                {`Submit project id ${projectId} to the `}
                <Link
                  href="/#/api_console"
                  className={classes.link}
                >
                  API console
                </Link>
                {' or '}
                <Link
                  href="https://github.com/biglocalnews/bln-python-client"
                  className={classes.link}
                >
                  Python client
                </Link>
                .
              </Typography>
            )}
          />
        </ListItem>
        <ListItem divider>
          <ListItemIcon className={classes.warning}>
            <DeleteIcon fontSize="large" color="inherit" />
          </ListItemIcon>
          <ListItemText
            primary="Delete project"
            secondary={(
              <Typography variant="body2" className={classes.warning}>
                WARNING: This will delete all your data. Proceed with caution.
              </Typography>
            )}
          />
          <ListItemSecondaryAction>
            <Button
              variant="outlined"
              className={classes.warningButton}
              onClick={(): void => {
                setOpenConfirmation(true);
              }}
            >
              Delete
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Dialog open={openConfirmation} onClose={cancelDelete}>
        <DialogTitle className={classes.dialogTitle}>
          Delete project
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This will permanently delete the project and its files. Please type
            the project name below to confirm.
          </DialogContentText>
          <TextField
            fullWidth
            margin="dense"
            autoComplete="off"
            name="projectName"
            label={projectName}
            onChange={(event): void => setConfirmationValue(event.target.value)}
            id="projectName"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={cancelDelete}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            disabled={!canDelete}
            onClick={deleteProject}
            className={classes.warningButton}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
