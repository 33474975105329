import { useMutation } from '@apollo/client';
import {
  Button,
  ListItemText,
  ListItemIcon,
  Menu,
  MenuItem,
  Snackbar,
  Theme,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import {
  ArrowDropDown,
  Power as PluginIcon,
  Link as LinkIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import React, {
  ReactElement,
  SyntheticEvent,
  useState,
} from 'react';
import { Colors } from '../../styles';
import * as Q from '../../gql';
import * as T from '../../types';
import * as U from '../../utils';

declare const DATASETTE_INSTANCE: string;

const useStyles = makeStyles((theme: Theme) => ({
  iconWrapper: {
    minWidth: 0,
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  file: T.File;
  canDelete: boolean;
  project: T.ProjectData;
  refetchProject: () => void;
  setError: T.SetState<string | undefined>;
}

export default function FilesItemMenu(props: Props): ReactElement {
  const {
    file, canDelete, project, refetchProject, setError,
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [fileURI, setFileURI] = useState<string>('');
  const [copyDialogOpen, setCopyDialogOpen] = useState<boolean>(false);
  const [fileToDelete, setFileToDelete] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const classes = useStyles();
  const [createFileDownloadURI] = useMutation(Q.createFileDownloadURI, {
    variables: {
      input: {
        fileName: file.name,
        projectId: project.id,
      },
    },
    onCompleted({ createFileDownloadUri: res }) {
      U.checkMutationError(res, setError);
      if (res?.ok?.uri) {
        setFileURI(res.ok.uri);
      }
    },
  });
  const [deleteFile] = useMutation(Q.deleteFile, {
    onError: (e) => U.checkApolloError(e, setError),
    update(cache, { data }) {
      U.checkMutationError(data?.deleteFile, setError);
      if (!data?.deleteFile?.ok) {
        return;
      }
      refetchProject();
    },
    onCompleted: () => {
      setDeleteDialogOpen(false);
      setTimeout(() => {
        setFileToDelete(null);
      }, 150);
    },
  });

  const closeCopyDialog = (event?: SyntheticEvent, reason?: string): void => {
    if (reason === 'clickaway') {
      return;
    }
    setCopyDialogOpen(false);
  };
  const closeDeleteDialog = (event?: SyntheticEvent, reason?: string): void => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleteDialogOpen(false);
  };

  function remove(file: T.File): void {
    setFileToDelete(file.name);
    setDeleteDialogOpen(true);
    deleteFile({
      variables: { input: { fileName: file.name, projectId: project.id } },
    });
  }

  return (
    <div>
      <Button
        style={{ textTransform: 'none', color: Colors.GREY }}
        size="small"
        variant="outlined"
        onClick={(e): void => {
          createFileDownloadURI();
          setAnchorEl(e.currentTarget);
        }}
        endIcon={<ArrowDropDown />}
      >
        More Actions
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={(): void => setAnchorEl(null)}
      >
        {file.name.endsWith('.csv') && (
          <MenuItem
            onClick={(): void => {
              const form = document.createElement("form");
              form.setAttribute("method", "post");
              form.setAttribute("action", `${DATASETTE_INSTANCE}/-/big-local-open`);
              form.setAttribute("target", "_blank");

              const tokenField = document.createElement("input");
              tokenField.setAttribute("type", "hidden");
              tokenField.setAttribute("name", "remember_token");
              tokenField.setAttribute("value", U.getCookie('remember_token')!);
              form.appendChild(tokenField);

              const projectIdField = document.createElement("input");
              projectIdField.setAttribute("type", "hidden");
              projectIdField.setAttribute("name", "project_id");
              projectIdField.setAttribute("value", project.id);
              form.appendChild(projectIdField);

              const filenameField = document.createElement("input");
              filenameField.setAttribute("type", "hidden");
              filenameField.setAttribute("name", "filename");
              filenameField.setAttribute("value", file.name);
              form.appendChild(filenameField);

              document.body.appendChild(form);
              form.submit();

              setAnchorEl(null);
            }}
          >
            <ListItemIcon className={classes.iconWrapper}>
              <PluginIcon />
            </ListItemIcon>
            <ListItemText primary="Open in Datasette" />
          </MenuItem>
        )}
        <CopyToClipboard
          text={fileURI}
          onCopy={(): void => {
            setAnchorEl(null);
            setCopyDialogOpen(true);
          }}
        >
          <MenuItem>
            <ListItemIcon className={classes.iconWrapper}>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText primary="Get temporary link" />
          </MenuItem>
        </CopyToClipboard>
        {canDelete && (
          <MenuItem
            onClick={(): void => {
              remove(file);
              setAnchorEl(null);
            }}
          >
            <ListItemIcon className={classes.iconWrapper}>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText primary="Delete file" />
          </MenuItem>
        )}
      </Menu>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={copyDialogOpen}
        onClose={closeCopyDialog}
        autoHideDuration={3000}
      >
        <Alert
          elevation={6}
          onClose={closeCopyDialog}
          variant="filled"
          severity="success"
        >
          Copied link. Links expire in 7 days.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={deleteDialogOpen}
        onClose={closeDeleteDialog}
      >
        <Alert
          elevation={6}
          onClose={closeDeleteDialog}
          variant="filled"
          severity="success"
        >
          {`Deleting ${fileToDelete}.`}
        </Alert>
      </Snackbar>
    </div>
  );
}
