import {
  ListItem, ListItemIcon, ListItemText, Theme,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement } from 'react';
import { Colors } from '../../styles';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: 'inherit',
    minWidth: theme.spacing(5),
  },
  root: {
    backgroundColor: theme.palette.common.white,
    '&$selected, &$focusVisible': {
      color: Colors.HYPERLINK,
      backgroundColor: theme.palette.common.white,
    },
    '&$selected:hover, &:hover': {
      backgroundColor: theme.palette.common.white,
    },
    "&:hover": {
      color: Colors.HYPERLINK,
    },
  },
  selected: {},
  focusVisible: {},
}));

interface Props {
  // eslint-disable-next-line react/no-unused-prop-types
  key: string;
  label: string;
  onClick: () => void;
  dividerAfter?: boolean;
  expandable?: boolean;
  expanded?: boolean;
  focus?: boolean;
  icon?: ReactElement;
  selected?: boolean;
  subNav?: boolean;
}

export default function NavItem(props: Props): ReactElement {
  const {
    dividerAfter,
    expandable,
    expanded,
    focus,
    icon,
    label,
    onClick,
    selected,
    subNav,
  } = props;

  const classes = useStyles();
  const textVariant = subNav ? 'body2' : 'body1';

  return (
    <ListItem
      autoFocus={focus}
      button
      classes={{
        button: classes.root,
        selected: classes.selected,
        focusVisible: classes.focusVisible,
      }}
      disableRipple
      disableTouchRipple
      divider={dividerAfter}
      selected={selected}
      onClick={onClick}
    >
      {icon ? (
        <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      ) : null}
      <ListItemText
        primary={label}
        primaryTypographyProps={{ variant: textVariant }}
      />
      {expandable && <>{expanded ? <ExpandLess /> : <ExpandMore />}</>}
    </ListItem>
  );
}
