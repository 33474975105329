import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Tabs,
  Tab,
  Theme,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Close as CloseIcon,
  InsertDriveFile as FileIcon,
  Person as UserIcon,
  Group as GroupIcon,
  Settings as ManageIcon,
} from '@material-ui/icons';
import { useMutation } from '@apollo/client';
import React, {
  useEffect,
  useState,
  ChangeEvent,
  FormEvent,
  ReactElement,
} from 'react';
import equal from 'fast-deep-equal';
import {
  Contact, Field, Files, ProjectManager, ProjectRoles,
} from '..';
import * as Q from '../../gql';
import * as T from '../../types';
import * as U from '../../utils';

// based on this example: https://tinyurl.com/y2qa9oku
const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: theme.spacing(1),
  },
  description: {
    padding: theme.spacing(0, 1, 0, 1),
  },
  dialog: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  emailIcon: {
    paddingRight: 5,
  },
  nameField: {
    paddingLeft: 0,
  },
  contactField: {
    paddingLeft: 0,
    marginBottom: theme.spacing(2),
  },
  form: {
    display: 'flex',
    marginTop: theme.spacing(1),
    width: '100%',
    justifyContent: 'center',
  },
  linkWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 1,
    alignItems: 'center',
  },
  link: {
    paddingLeft: 5,
    color: theme.palette.secondary.light,
  },
  paper: {
    padding: theme.spacing(0, 4, 0, 4),
  },
  submit: {
    margin: theme.spacing(2, 0),
    textTransform: 'none',
  },
  tabs: {
    height: 'auto',
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 1, 0, 1),
  },
  tabsIndicator: {
    display: 'none',
  },
  tabLabelIcon: {
    minHeight: 48,
    '& $tabWrapper > *:first-child': {
      marginRight: theme.spacing(2),
      marginBottom: 0,
    },
  },
  tabPanel: {
    marginTop: theme.spacing(2),
    justifyContent: 'center',
    flexBasis: 415,
  },
  tabRoot: {
    border: '1px solid #e9e9e9',
    '&:not(:first-of-type)': {
      marginLeft: -1,
    },
    background: '#f7f7f7',
    opacity: 1,
  },
  tabSelected: {
    borderBottomWidth: 0,
    background: '#ffffff',
    '& $wrapper': {
      opacity: 1,
    },
  },
  tabWrapper: {
    opacity: 0.7,
    display: 'flex',
    flexDirection: 'row',
  },
}));

interface Props {
  open: boolean;
  project: T.ProjectData | null;
  projectRole: T.ProjectRole;
  users: any[];
  groups: any[];
  setError: T.SetState<string | undefined>;
  onClose: () => void;
}

export default function ProjectEditor(props: Props): ReactElement {
  const {
    open,
    project,
    projectRole,
    users,
    groups,
    setError,
    onClose,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const isAdmin: boolean = projectRole === T.ProjectRole.ADMIN;
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [input, setInput] = useState<T.UpdateProjectInput>({
    id: '',
    name: '',
    contactMethod: T.ContactMethod.EMAIL,
    contact: '',
    description: '',
    isOpen: false,
    userRoles: {
      admin: [],
      editor: [],
      viewer: [],
    },
    groupRoles: {
      admin: [],
      editor: [],
      viewer: [],
    },
  });
  const [updateProject] = useMutation<T.UpdateProject>(Q.updateProject, {
    onError: (e) => U.checkApolloError(e, setError),
    onCompleted({ updateProject: res }) {
      U.checkMutationError(res, setError);
    },
    refetchQueries: [
      { query: Q.effectiveProjectRoles },
      { query: Q.openProjects },
    ],
  });
  const [deleteProject] = useMutation<T.DeleteProject>(Q.deleteProject, {
    onError: (e) => U.checkApolloError(e, setError),
    onCompleted({ deleteProject: res }) {
      U.checkMutationError(res, setError);
      onClose();
    },
    refetchQueries: [
      { query: Q.effectiveProjectRoles },
      { query: Q.openProjects },
    ],
  });

  function projectToInput(p: T.ProjectData): T.UpdateProjectInput {
    const inpt: T.UpdateProjectInput = {
      id: p.id,
      name: p.name,
      contactMethod: p.contactMethod,
      contact: p.contact,
      description: p.description || '',
      isOpen: p.isOpen || false,
      userRoles: {
        admin: [],
        editor: [],
        viewer: [],
      },
      groupRoles: {
        admin: [],
        editor: [],
        viewer: [],
      },
    };

    // Yank out the user and group roles for this project
    // from the nested GQL structure, simplifying it down
    // to a list of object dictionaries
    if (p.userRoles?.edges) {
      p.userRoles.edges.forEach((edge) => {
        if (edge?.node?.role && edge?.node?.user?.username) {
          const role = edge.node.role.toLowerCase();
          (inpt.userRoles as any)[role].push(edge.node.user);
        }
      });
    }
    if (p.groupRoles?.edges) {
      p.groupRoles.edges.forEach((edge) => {
        if (edge?.node?.role && edge?.node?.group?.name) {
          const role = edge.node.role.toLowerCase();
          (inpt.groupRoles as any)[role].push(edge.node.group);
        }
      });
    }
    return inpt;
  }

  useEffect(() => {
    if (project) {
      setInput(projectToInput(project));
    }
  }, [project]);

  function handleTabChange(
    _: ChangeEvent<Record<string, unknown>>,
    value: number,
  ): void {
    setTabIndex(value);
  }

  function onSubmit(event: FormEvent<HTMLFormElement>): void {
    // No normal clicking
    event.preventDefault();

    // Flatten user and group roles to just what we need for the GQL update query
    input!.userRoles!.admin = input!.userRoles!.admin.map((d: any) => d.username);
    input!.userRoles!.editor = input!.userRoles!.editor.map((d: any) => d.username);
    input!.userRoles!.viewer = input!.userRoles!.viewer.map((d: any) => d.username);
    input!.groupRoles!.admin = input!.groupRoles!.admin.map((d: any) => d.name);
    input!.groupRoles!.editor = input!.groupRoles!.editor.map((d: any) => d.name);
    input!.groupRoles!.viewer = input!.groupRoles!.viewer.map((d: any) => d.name);

    // Send the update
    updateProject({ variables: { input } });

    // Trigger the close callback
    onClose();
  }

  const nameLabel = U.nameLabel(
    'Project',
    input.name!,
    U.isValidProjectName,
    'Only letters, numbers, and [-_ ]',
  );

  const tabClasses = {
    labelIcon: classes.tabLabelIcon,
    root: classes.tabRoot,
    selected: classes.tabSelected,
    wrapper: classes.tabWrapper,
  };

  const canSave = project
    && U.isValidProjectName(input.name!)
    && U.isValidContact(input.contactMethod!, input.contact!)
    && (input.userRoles!.admin.length > 0 || input.groupRoles!.admin.length > 0)
    && !equal(projectToInput(project), input);

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <Grid item container className={classes.closeIcon}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent className={classes.dialog}>
        <form onSubmit={onSubmit} noValidate>
          <Grid item container className={classes.form}>
            <Grid
              justifyContent="flex-start"
              item
              container
              direction="row"
              xs={12}
              sm={8}
              spacing={2}
            >
              <Grid item xs={12} md={12} lg className={classes.nameField}>
                <Field
                  name="projectName"
                  label={nameLabel}
                  value={input.name!}
                  disabled={!isAdmin}
                  required
                  setValue={(name): void => {
                    setInput((v) => ({ ...v, name }));
                  }}
                  isValid={(n): boolean => U.isValidProjectName(n)}
                />
              </Grid>
              <Grid item sm={12} md={12} lg className={classes.contactField}>
                <Contact
                  contactMethod={input.contactMethod!}
                  setContactMethod={(contactMethod: T.ContactMethod): void => {
                    setInput((v) => ({ ...v, contactMethod }));
                  }}
                  contact={input.contact!}
                  setContact={(contact: string): void => {
                    setInput((v) => ({ ...v, contact }));
                  }}
                  disabled={!isAdmin}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={8} className={classes.description}>
              <Field
                disabled={!isAdmin}
                name="description"
                label="Description"
                value={input.description || ''}
                setValue={(description): void => {
                  setInput((v) => ({ ...v, description }));
                }}
                isValid={(): boolean => true}
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
          <Grid item container justifyContent="center">
            <Grid item className={classes.tabs} xs={12} sm={8}>
              <Tabs
                value={tabIndex}
                classes={{ indicator: classes.tabsIndicator }}
                onChange={handleTabChange}
                centered={!mobile}
                variant={mobile ? 'scrollable' : 'fullWidth'}
                scrollButtons="auto"
              >
                <Tab
                  label="Files"
                  value={0}
                  icon={<FileIcon />}
                  classes={tabClasses}
                />
                <Tab
                  label="Users"
                  value={1}
                  icon={<UserIcon />}
                  classes={tabClasses}
                />
                <Tab
                  label="Groups"
                  value={2}
                  icon={<GroupIcon />}
                  classes={tabClasses}
                />
                {isAdmin && (
                  <Tab
                    label="Manage"
                    value={3}
                    icon={<ManageIcon />}
                    classes={tabClasses}
                  />
                )}
              </Tabs>
            </Grid>
          </Grid>
          <Grid item container spacing={2} className={classes.tabPanel}>
            <Grid item className={classes.form} xs={11} sm={7}>
              {tabIndex === 0 && (
                <Files
                  role={projectRole}
                  project={project!}
                  setError={setError}
                />
              )}
              {tabIndex === 1 && (
                <ProjectRoles
                  title="Users"
                  disabled={!isAdmin}
                  names={users}
                  roles={input.userRoles!}
                  setRoles={(userRoles: T.ProjectRolesInput): void => {
                    setInput((v) => ({ ...v, userRoles }));
                  }}
                />
              )}
              {tabIndex === 2 && (
                <ProjectRoles
                  title="Groups"
                  disabled={!isAdmin}
                  names={groups}
                  roles={input.groupRoles!}
                  setRoles={(groupRoles: T.ProjectRolesInput): void => {
                    setInput((v) => ({ ...v, groupRoles }));
                  }}
                />
              )}
              {isAdmin && tabIndex === 3 && (
                <ProjectManager
                  isOpen={input.isOpen!}
                  handleOpenToggle={(): void => {
                    const isOpen = !input.isOpen;
                    setInput((v) => ({ ...v, isOpen }));
                  }}
                  projectName={input.name!}
                  deleteProject={(): void => {
                    deleteProject({ variables: { input: { id: input.id } } });
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Grid
            className={classes.form}
            item
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {isAdmin && (
              <Grid item xs={6} sm={4}>
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  className={classes.submit}
                  disabled={!canSave}
                >
                  Save
                </Button>
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
