import React, { ReactElement } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import * as T from '../../types';
import * as U from '../../utils';

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any;
}

/* eslint react/jsx-props-no-spreading: 0 */
export default function PrivateRoute(props: PrivateRouteProps): ReactElement {
  const { component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(routeProps) => (U.isLoggedIn() ? (
        <Component {...routeProps} />
      ) : (
        <Redirect
          to={{
            pathname: T.Route.LOGIN,
            state: { from: routeProps.location },
          }}
        />
      ))}
    />
  );
}
