import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import React, {
  useEffect, useState, FormEvent, ReactElement,
} from 'react';
import equal from 'fast-deep-equal';
import { Contact, Field, GroupRoles } from '..';
import * as Q from '../../gql';
import * as T from '../../types';
import * as U from '../../utils';

// based on this example: https://tinyurl.com/y2qa9oku
const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  dialog: {
    padding: theme.spacing(0),
    height: '100%',
  },
  form: {
    display: 'flex',
    marginTop: theme.spacing(1),
    width: '100%',
  },
  paper: {
    alignItems: 'left',
    margin: theme.spacing(4, 4),
  },
  submit: {
    margin: theme.spacing(0, 0, 2, 0),
    textTransform: 'none',
  },
}));

interface Props {
  open: boolean;
  group: T.GroupData | null;
  groupRole: T.GroupRole;
  users: any[];
  groups: any[];
  setError: T.SetState<string | undefined>;
  onClose: () => void;
}

export default function GroupEditor(props: Props): ReactElement {
  const {
    open,
    group,
    groupRole,
    users,
    groups,
    onClose,
    setError,
  } = props;
  const classes = useStyles();
  const isAdmin: boolean = groupRole === T.GroupRole.ADMIN;
  const [input, setInput] = useState<T.UpdateGroupInput>({
    id: '',
    name: '',
    contactMethod: T.ContactMethod.EMAIL,
    contact: '',
    description: '',
    userRoles: {
      admin: [],
      member: [],
    },
  });
  const [updateGroup] = useMutation<T.UpdateGroup>(Q.updateGroup, {
    onError: (e) => U.checkApolloError(e, setError),
    onCompleted({ updateGroup: res }) {
      U.checkMutationError(res, setError);
    },
    refetchQueries: [{ query: Q.groupRoles }, { query: Q.groupNames }],
  });

  function groupToInput(g: T.GroupData): T.UpdateGroupInput {
    const inpt: T.UpdateGroupInput = {
      id: g.id,
      name: g.name,
      contactMethod: g.contactMethod,
      contact: g.contact,
      description: g.description || '',
      userRoles: {
        admin: [],
        member: [],
      },
    };
    if (g.userRoles?.edges) {
      g.userRoles.edges.forEach((edge) => {
        if (edge?.node?.role && edge?.node?.user?.username) {
          const role = edge.node.role.toLowerCase();
          (inpt.userRoles as any)[role].push(edge.node.user);
        }
      });
    }
    return inpt;
  }

  useEffect(() => {
    if (group) {
      setInput(groupToInput(group));
    }
  }, [group]);

  function onSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    input!.userRoles!.admin = input!.userRoles!.admin.map((d: any) => d.username);
    input!.userRoles!.member = input!.userRoles!.member.map((d: any) => d.username);
    updateGroup({ variables: { input } });
    onClose();
  }

  let nameLabel = U.nameLabel(
    'Group',
    input.name!,
    U.isValidShortName,
    'Only letters, numbers and _',
  );

  let nameIsTaken = false;
  // if it's a new group or the group's name has changed
  if (input.name !== group?.name) {
    // check if the group name is taken
    if (groups.map((d) => d.username).includes(input.name!)) {
      nameLabel = 'Already Used';
      nameIsTaken = true;
    }
  }
  const canSave = group
    && U.isValidShortName(input.name!)
    && !nameIsTaken
    && U.isValidContact(input.contactMethod!, input.contact!)
    && input.userRoles!.admin.length > 0
    && !equal(groupToInput(group), input);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle style={{ textAlign: 'center' }}>Group</DialogTitle>
      <DialogContent dividers className={classes.dialog}>
        <div className={classes.paper}>
          <form className={classes.form} noValidate onSubmit={onSubmit}>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Field
                  name="groupName"
                  label={nameLabel}
                  value={input.name!}
                  disabled={!isAdmin}
                  required
                  setValue={(name): void => {
                    setInput((v) => ({ ...v, name }));
                  }}
                  isValid={(v): boolean => U.isValidShortName(v)}
                />
              </Grid>
              <Grid item xs={12}>
                <Contact
                  contactMethod={input.contactMethod!}
                  setContactMethod={(contactMethod: T.ContactMethod): void => {
                    setInput((v) => ({ ...v, contactMethod }));
                  }}
                  contact={input.contact!}
                  setContact={(contact: string): void => {
                    setInput((v) => ({ ...v, contact }));
                  }}
                  disabled={!isAdmin}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  disabled={!isAdmin}
                  name="description"
                  label="Description"
                  value={input.description || ''}
                  setValue={(description): void => {
                    setInput((v) => ({ ...v, description }));
                  }}
                  isValid={(): boolean => true}
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <GroupRoles
                  disabled={!isAdmin}
                  names={users}
                  roles={input.userRoles!}
                  setRoles={(userRoles: T.GroupRolesInput): void => {
                    setInput((v) => ({ ...v, userRoles }));
                  }}
                />
              </Grid>
              {isAdmin && (
                <Grid item xs={8}>
                  <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    className={classes.submit}
                    disabled={!canSave}
                  >
                    Save
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
}
