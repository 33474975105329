import {
  Avatar,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Delete as DeleteIcon, FileCopy as CopyIcon } from '@material-ui/icons';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import React, { useState, ReactElement } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { AlertDialog, TitleSection } from '..';
import * as Q from '../../gql';
import * as T from '../../types';
import * as U from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1),
    textTransform: 'none',
  },
  main: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
    marginTop: theme.spacing(7),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0, 4, 0, 4),
  },
}));

export default function ManageKeys(): ReactElement {
  const history = useHistory();
  if (!U.isLoggedIn()) {
    history.push(T.Route.LOGIN);
  }
  const classes = useStyles();
  const [error, setError] = useState<string | undefined>(undefined);
  const { data: qTokens } = U.useQ<T.PersonalTokens>(
    Q.personalTokens,
    setError,
  );
  const edges = qTokens?.user?.personalTokens?.edges || [];
  const [createToken] = useMutation(Q.createPersonalToken, {
    onCompleted({ createPersonalToken }) {
      if (createPersonalToken.err) {
        setError(createPersonalToken.err);
      }
    },
    refetchQueries: [{ query: Q.personalTokens }],
  });
  const [revokeToken] = useMutation(Q.revokePersonalToken, {
    onError: (e) => U.checkApolloError(e, setError),
    onCompleted({ revokePersonalToken }) {
      if (revokePersonalToken.err) {
        setError(revokePersonalToken.err);
      }
    },
    refetchQueries: [{ query: Q.personalTokens }],
  });

  const squash = (v: string): string => `${v.slice(0, 10)}...${v.slice(-10)}`;
  return (
    <Grid container className={classes.main}>
      <Grid
        container
        spacing={4}
        direction="column"
        className={classes.content}
      >
        <AlertDialog error={error} clear={(): void => setError(undefined)} />
        <TitleSection
          label="Manage Keys"
          description="Generate an API key. This isn't necessary to use our
        built-in API console."
        />
        <Grid item container direction="column" spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              fullWidth
              className={classes.button}
              onClick={(): void => {
                createToken();
              }}
            >
              Generate Key
            </Button>
          </Grid>
          <Grid item xs={6}>
            <List>
              {edges.map((e) => (
                <ListItem key={e!.node!.token}>
                  <ListItemAvatar>
                    <Avatar>
                      <CopyToClipboard text={e!.node!.token}>
                        <IconButton aria-label="copy">
                          <CopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={squash(e!.node!.token!)} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="remove"
                      onClick={(): void => {
                        revokeToken({
                          variables: { input: { token: e!.node!.token } },
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
