import {
  Chip, Grid, InputBase, Theme
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AddCircle as AddTagIcon } from "@material-ui/icons";
import { useMutation } from "@apollo/client";
import React, {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  useRef,
  useState
} from "react";

import * as Q from "../../gql";
import * as T from "../../types";
import * as U from "../../utils";
import { Colors } from "../../styles";

const useStyles = makeStyles((theme: Theme) => ({
  autocomplete: {
    position: "absolute",
    top: 0
  },
  deleteIcon: {
    color: Colors.HYPERLINK
  },
  error: {
    color: Colors.STANFORD_BRIGHT_RED
  },
  hiddenIcon: {
    opacity: 0
  },
  input: {
    fontSize: theme.typography.subtitle2.fontSize,
    padding: 0
  },
  inputWrapper: {
    position: "relative",
    paddingBottom: 3
  },
  root: {},
  tag: {
    color: Colors.HYPERLINK,
    borderColor: Colors.HYPERLINK,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[500]
  }
}));

interface Props {
  project: T.ProjectData | T.OpenProjectData;
  role: T.ProjectRole;
  setError: T.SetState<string | undefined>;
  tags: T.Tag[];
}
export default function TagsDisplay(props: Props): ReactElement {
  const {
    project, role, setError, tags
  } = props;
  const classes = useStyles();
  const [input, setInput] = useState<T.CreateTagInput>({ name: "" });
  const [suggestion, setSuggestion] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  const projectTagNames = project.tags!.edges.map((e) => e!.node!.tag!.name);
  const canEdit = U.isMyProject(project) && role === T.ProjectRole.ADMIN;

  const [updateProject] = useMutation<T.UpdateProject>(Q.updateProject, {
    onError: (e) => U.checkApolloError(e, setError),
    onCompleted({ updateProject: res }) {
      U.checkMutationError(res, setError);
    }
  });

  const addTag = (newTagName: string): void => {
    const tag = tags.find((t) => t.name === newTagName);

    if (tag) {
      updateProject({
        variables: {
          input: {
            id: project.id,
            tags: [...projectTagNames, tag.name]
          }
        }
      });
    }
  };

  const [createTag] = useMutation<T.CreateTag>(Q.createTag, {
    onError: (e) => U.checkApolloError(e, setError),
    refetchQueries: [{ query: Q.tags }],
    awaitRefetchQueries: true,
    onCompleted({ createTag: res }) {
      U.checkMutationError(res, setError);
      if (res?.ok) {
        addTag(res.ok);
      }
    }
  });

  const removeTag = (tagName: string) => (): void => {
    // Conditional checks to make sure it is not an Open Project before
    // executing. Only needed to suppress ts error.
    if (canEdit) {
      const tagEdges = project.tags!.edges.filter(
        (e) => e!.node!.tag!.name !== tagName
      );
      const updatedProject = {
        ...project,
        tags: { ...project.tags!, edges: tagEdges }
      };
      updateProject({
        variables: {
          input: {
            id: project.id,
            tags: tagEdges.map((e) => e!.node!.tag!.name)
          }
        },
        optimisticResponse: {
          updateProject: {
            __typename: "UpdateProject",
            ok: {
              ...updatedProject
            },
            err: ""
          }
        }
      });
    }
  };

  function clearInputs(): void {
    setInput({ name: "" });
    setSuggestion("");
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const value = event.target.value.toLowerCase();
    setInput({ name: value });
    const match = tags.find((tag) => tag.name.startsWith(value));
    // don't  autocomplete until there are at least two letters entered
    if (event.target.value.length >= 2 && match) {
      setSuggestion(match.name);
    } else {
      setSuggestion("");
    }
  }

  function handleKeyDown(event: KeyboardEvent): void {
    if (event.key === "Tab") {
      event.preventDefault();
      if (suggestion) {
        setInput({ name: suggestion });
      }
    } else if (event.key === "Enter") {
      event.preventDefault();

      const tagExists = tags.filter((t) => t.name === input.name).length > 0;

      if (U.isValidTag(input.name)) {
        if (!tagExists) {
          createTag({ variables: { input } });
        } else if (!projectTagNames.includes(input.name)) {
          addTag(input.name);
        }
      } else {
        setError(
          `Tags must be lowercase and cannot contain spaces or special
          characters other than dashes or underscores.`
        );
      }
      clearInputs();
    } else if (event.key === "Escape") {
      clearInputs();
    } else if (event.key === " ") {
      event.preventDefault();
      setInput({ name: `${input.name} ` });
    }
  }

  function handleBlur(): void {
    clearInputs();
  }

  function handleClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  const tagEdges = [...(project.tags?.edges || [])];

  return (
    <Grid container alignItems="center" className={classes.root}>
      {tagEdges
        .sort((a, b) => (a!.node!.tag!.name > b!.node!.tag!.name ? 1 : -1))
        .map((e) => (
          <Grid item key={e!.node!.tag!.name}>
            <Chip
              label={e!.node!.tag!.name}
              variant="outlined"
              onDelete={canEdit ? removeTag(e!.node!.tag!.name) : undefined}
              size="small"
              classes={{ root: classes.tag, deleteIcon: classes.deleteIcon }}
            />
          </Grid>
        ))}
      {canEdit && (
        <Grid item className={classes.inputWrapper}>
          <InputBase
            placeholder={suggestion || undefined}
            startAdornment={(
              <AddTagIcon
                fontSize="small"
                color="disabled"
                className={classes.hiddenIcon}
              />
            )}
            disabled
            classes={{ root: classes.autocomplete, input: classes.input }}
          />
          <InputBase
            inputRef={inputRef}
            placeholder="Add tag"
            startAdornment={(
              <AddTagIcon
                color="disabled"
                onClick={(): void => {
                  if (inputRef.current !== null) {
                    inputRef.current.focus();
                  }
                }}
                fontSize="small"
              />
            )}
            value={input.name}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onClick={handleClick}
            onBlur={handleBlur}
            error={input.name.length > 2 && !U.isValidTag(input.name)}
            classes={{ error: classes.error, input: classes.input }}
          />
        </Grid>
      )}
    </Grid>
  );
}
