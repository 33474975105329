import { TextField } from '@material-ui/core';
import React, { ReactElement } from 'react';
import * as U from '../../utils';

interface Props {
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
}

export default function Email(props: Props): ReactElement {
  const { value, setValue, disabled } = props;
  const isValid = U.isValidEmail(value) || value === '';

  return (
    <TextField
      disabled={disabled}
      fullWidth
      required
      style={{ flexGrow: 1 }}
      error={!isValid}
      autoComplete="email"
      id="email"
      label={isValid ? 'Contact Email' : 'Invalid Email'}
      name="email"
      value={value}
      onChange={(event): void => setValue(event.target.value)}
      variant="outlined"
    />
  );
}
