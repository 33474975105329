import React, { ReactElement, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export default function Border(props: Props): ReactElement {
  const { children } = props;
  const style = {
    alignItems: 'center',
    padding: 0,
    border: 1,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: 3,
    borderStyle: 'solid',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  };
  return <div style={style}>{children}</div>;
}
