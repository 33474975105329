/* eslint react/jsx-props-no-spreading: 0 */
import React, { ReactNode, ReactElement, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

interface Props {
  children: ReactNode;
  handleFiles: (files: File[]) => void;
  accept?: string;
  disabled?: boolean;
}

export default function DropZone(props: Props): ReactElement {
  const {
    children, accept, handleFiles, disabled,
  } = props;
  const onDrop = useCallback(handleFiles, []);
  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    // isDragActive,
    // isDragReject,
  } = useDropzone({
    disabled,
    noClick: true,
    onDrop,
    accept,
  });

  return (
    <div
      {...getRootProps({
        style: {
          backgroundColor: isDragAccept ? 'rgb(0, 0, 0, 0.1)' : 'white',
        },
      })}
    >
      <input {...getInputProps()} />
      {children}
    </div>
  );
}
