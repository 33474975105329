import {
  AppBar,
  Button,
  Grid,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState, ReactElement } from 'react';
import {
  AlertDialog,
  ProjectCard,
  Search,
  TagsFilter,
} from '..';
import * as Q from '../../gql';
import * as T from '../../types';
import * as U from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    marginLeft: '0.25em',
  },
  button: {
    padding: 0,
    textTransform: 'none',
    fontWeight: 550,
  },
  buttonWrapper: {
    alignSelf: 'self-end',
    flexGrow: 0,
  },
  cardsWrapper: {
    overflow: 'hidden',
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: "50px",
    },
  },
  createWrapper: {
    flexGrow: 0,
  },
  hed: {
    flexGrow: 1,
  },
  main: {
    maxWidth: theme.breakpoints.values.md,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      minWidth: theme.breakpoints.values.md,
    },
  },
  mobile: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mobileCreate: {
    paddingTop: theme.spacing(1),
  },
  projectCount: {
    fontWeight: 400,
    color: theme.palette.grey[700],
  },
  projectCountWrapper: {
    flexGrow: 1,
    marginLeft: '0.25em',
  },
  search: {
    position: 'relative',
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      width: 'auto',
    },
  },
  searchInfo: {
    paddingBottom: theme.spacing(1),
  },
  searchWrapper: {
    maxWidth: theme.breakpoints.values.md,
    position: 'sticky',
    [theme.breakpoints.up('sm')]: {
      top: 0,
    },
    top: 55,
    backgroundColor: theme.palette.common.white,
    zIndex: 1000,
  },
  title: {
    display: 'none',
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  titleMobile: {
    fontWeight: 700,
    paddingTop: theme.spacing(1),
  },
}));

export default function OpenProjects(): ReactElement {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [error, setError] = useState<string | undefined>(undefined);
  const isCached = U.useIsCached<T.OpenProjects>(Q.openProjects);
  const { data: qTags } = U.useQ<T.Tags>(Q.tags, setError);
  const { data: qOpenProjects } = U.useQ<T.OpenProjects>(
    Q.openProjects,
    setError,
  );
  const edges = qOpenProjects?.openProjects?.edges || [];
  const tags = qTags?.tags?.edges.map((e) => e!.node!) || [];
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

  function getTagNames(proj: T.OpenProjectEdge): string[] {
    if (!proj) {
      return [];
    }
    return proj!.node!.tags!.edges.map((edge) => edge!.node!.tag!.name);
  }

  const isFiltered = searchResults.length > 0 || searchInput !== '';

  const noResults = searchResults.length === 0 && searchInput !== '';
  const keys = [
    'node.name',
    'node.description',
    'node.tags.edges.node.tag.name',
  ];

  const skeletonHeight = 185;

  useEffect(() => {
    if (!U.isLoggedIn()) {
      history.push(T.Route.LOGIN);
    }
  });

  return (
    <div className={classes.main}>
      <AlertDialog error={error} clear={(): void => setError(undefined)} />
      <div className={classes.searchWrapper}>
        <div>
          <AppBar position="static" elevation={0} className={classes.appBar}>
            {isMobile && (
              <div className={classes.mobile}>
                <Typography variant="h5" className={classes.titleMobile}>
                  Open Projects
                </Typography>
              </div>
            )}
            <Toolbar disableGutters>
              <Typography variant="h5" className={classes.title}>
                Open Projects
              </Typography>
              <div className={classes.search}>
                <Search
                  projects={edges}
                  setSearchResults={setSearchResults}
                  searchInput={searchInput}
                  setSearchInput={setSearchInput}
                  searchKeys={keys}
                />
              </div>
            </Toolbar>
          </AppBar>
        </div>
        <div>
          <TagsFilter
            projects={edges}
            searchResults={searchResults}
            setSearchResults={setSearchResults}
            noResults={noResults}
            getTagNames={getTagNames}
          />
        </div>
        <Grid
          container
          className={classes.searchInfo}
          alignItems="center"
          direction="row"
        >
          <Grid item className={classes.projectCountWrapper}>
            <Typography variant="body2" className={classes.projectCount}>
              {!isCached
                ? 'Loading projects...'
                : `Displaying ${
                  isFiltered ? searchResults.length : edges.length
                } of ${edges.length} projects`}
            </Typography>
          </Grid>
          {isFiltered && (
          <Grid item className={classes.buttonWrapper}>
            <Button
              disabled={!isFiltered}
              color="primary"
              onClick={(): void => {
                setSearchInput('');
                setSearchResults([]);
              }}
              className={classes.button}
            >
              Show All
            </Button>
          </Grid>
          )}
        </Grid>
      </div>
      <div className={classes.cardsWrapper}>
        {noResults ? (
          <Typography variant="subtitle1">
            {`No results found for "${searchInput}."`}
          </Typography>
        ) : (
          <Grid
            container
            justifyContent="flex-start"
            direction="column"
            spacing={4}
          >
            {!isCached
              ? [1, 2, 3, 4].map((i) => (
                <Grid item key={i}>
                  <Skeleton variant="rect" height={skeletonHeight} />
                </Grid>
              ))
              : edges
                .filter((e) => (searchResults.length > 0
                  ? searchResults.includes(e!.node!.id)
                  : true))
                .sort(
                  (a, b) => {
                    if (a!.node!.name.toLowerCase() < b!.node!.name.toLowerCase()) {
                      return -1;
                    }
                    if (a!.node!.name.toLowerCase() > b!.node!.name.toLowerCase()) {
                      return 1;
                    }
                    return 0;
                  },
                )
                .map((e) => (
                  <Grid item key={e!.node!.id}>
                    <ProjectCard
                      project={e!.node!}
                      role={T.ProjectRole.VIEWER}
                      tags={tags}
                      onClick={(): void => {
                        const p = e!.node;
                        if (p!.isArchived && p!.archiveUrl) {
                          window.location.href = p!.archiveUrl;
                        } else {
                          history.push(`project/${e!.node!.id}`);
                        }
                      }}
                      setError={setError}
                    />
                  </Grid>
                ))}
          </Grid>
        )}
      </div>
    </div>
  );
}
