import {
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { CloudDownload as DownloadIcon } from '@material-ui/icons';
import React, { ReactElement } from 'react';
import { FilesItemMenu } from '..';
import * as Q from '../../gql';
import * as T from '../../types';
import * as U from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  actionMenu: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  item: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  role: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  text: {
    marginRight: theme.spacing(6),
  },
}));

export interface Props {
  file: T.File;
  project: T.ProjectData;
  refetchProject: () => void;
  canDelete: boolean;
  setError: T.SetState<string | undefined>;
}

export default function FilesItem(props: Props): ReactElement {
  const {
    file, canDelete, project, refetchProject, setError,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [createFileDownloadURI] = useMutation(Q.createFileDownloadURI, {
    variables: {
      input: {
        fileName: file.name,
        projectId: project.id,
      },
    },
    onCompleted({ createFileDownloadUri: res }) {
      U.checkMutationError(res, setError);
      if (res?.ok.name && res?.ok.uri) {
        const { name, uri } = res.ok;
        const link = document.createElement('a');
        link.href = uri;
        link.download = name;
        link.click();
      }
    },
  });

  const desktop = useMediaQuery(theme.breakpoints.up('lg'));

  function onClick(): void {
    createFileDownloadURI();
  }

  return (
    <ListItem key={file.name} divider className={classes.item}>
      <Grid container direction="row">
        <Grid item container md={12} lg={7} alignItems="center" direction="row">
          <ListItemIcon>
            <IconButton onClick={onClick}>
              <DownloadIcon />
            </IconButton>
          </ListItemIcon>
          <ListItemText primary={file.name} className={classes.text} />
        </Grid>
        <Grid
          item
          container
          md={12}
          lg={5}
          alignItems="center"
          justifyContent={!desktop ? 'center' : 'flex-end'}
        >
          <FilesItemMenu
            refetchProject={refetchProject}
            canDelete={canDelete}
            file={file}
            project={project}
            setError={setError}
          />
        </Grid>
      </Grid>
    </ListItem>
  );
}
