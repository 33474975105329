import Fuse from 'fuse.js';
import { Grid, InputBase, Theme } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/';
import React, { ChangeEvent, ReactElement } from 'react';
import * as T from '../../types';

interface Props {
  projects: (T.ProjectEdge | T.OpenProjectEdge | null)[];
  setSearchResults: T.SetState<string[]>;
  searchInput: string;
  setSearchInput: T.SetState<string>;
  searchKeys: string[];
}

const useStyles = makeStyles((theme: Theme) => ({
  searchWrapper: {
    border: '1px solid',
    borderColor: theme.palette.grey[400],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5, 1),
    width: '100%',
  },
}));

export default function Search(props: Props): ReactElement {
  const {
    projects,
    setSearchResults,
    searchInput,
    setSearchInput,
    searchKeys,
  } = props;
  const classes = useStyles();

  const searchOptions = {
    // 10k chars approx four pages of words arbitrary based on trial and error
    distance: 10000,
    keys: searchKeys,
    threshold: 0.3,
    useExtendedSearch: true,
    minMatchCharLength: 3,
  };

  const fuse = new Fuse(projects, searchOptions);

  function handleSearchInput(event: ChangeEvent<{ value: unknown }>): void {
    const input = event.target.value as string;
    setSearchInput(input);
    let results;
    let matches;
    if (input === '') {
      setSearchResults([]);
    } else {
      results = fuse.search(searchInput);
      matches = results.map((match) => match!.item!.node!.id);
      setSearchResults(matches);
    }
  }

  return (
    <Grid container>
      <Grid item className={classes.searchWrapper}>
        <InputBase
          fullWidth
          value={searchInput}
          onChange={handleSearchInput}
          startAdornment={<SearchIcon />}
          placeholder="Search"
        />
      </Grid>
    </Grid>
  );
}
