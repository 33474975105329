import { useMutation } from '@apollo/client';
import {
  Button, Grid, List, useTheme, useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CloudUpload as UploadIcon } from '@material-ui/icons';
import React, {
  ChangeEvent, useEffect, useState, ReactElement,
} from 'react';
import Axios from 'axios';
import { DropZone, FilesItem, FilesItemUploading } from '..';
import * as Q from '../../gql';
import * as T from '../../types';
import * as U from '../../utils';

interface Props {
  role: T.ProjectRole;
  project: T.ProjectData;
  setError: T.SetState<string | undefined>;
}

const useStyles = makeStyles(() => ({
  input: {
    display: 'none',
  },
  list: {
    overflow: 'auto',
    height: 320,
    paddingLeft: 10,
    paddingRight: 10,
  },
  button: {
    minWidth: '100%',
    backgroundColor: 'rgb(0,0,0,0.05)',
    textTransform: 'none',
  },
  buttonWrapper: {
    height: 'auto',
  },
  files: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    width: '100%',
    paddingBottom: 0,
    height: 'fit-content',
  },
  wrapper: {
    display: 'flex',
  },
}));

export default function Files(props: Props): ReactElement {
  const classes = useStyles({});
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { role, project, setError } = props;
  // TODO(danj): use fragment matcher; pass cache updates through
  // https://github.com/biglocalnews/bln/issues/99
  const canWrite = [T.ProjectRole.ADMIN, T.ProjectRole.EDITOR].includes(role);
  const [filesToUpload, setFilesToUpload] = useState<{
    [n: string]: { file: File; progress: number };
  }>({});
  const [polling, setPolling] = useState<boolean>(false);
  const {
    data: updatedProject,
    refetch: refetchProject,
    startPolling: startProjectPolling,
    stopPolling: stopProjectPolling,
  } = U.useQ<T.Node>(Q.node, setError, {
    variables: { id: project.id },
    fetchPolicy: 'cache-first',
  });
  const currentProject = (updatedProject?.node as T.ProjectData) ?? project;
  const files = currentProject!.files!.edges.map(
    (edge) => edge?.node as T.File,
  );

  files.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  const [createFileUploadURI] = useMutation<T.CreateFileUploadURI>(
    Q.createFileUploadURI,
    {
      onError: (e) => U.checkApolloError(e, setError),
      onCompleted({ createFileUploadUri: res }) {
        U.checkMutationError(res, setError);
        if (res?.ok?.name && res?.ok?.uri) {
          const { name } = res.ok;
          const fileData = filesToUpload[name];
          Axios.put(res.ok.uri, fileData.file, {
            onUploadProgress: (e): void => {
              fileData.progress = Math.round((e.loaded / e.total) * 100);
              setFilesToUpload((fs) => ({ ...fs, [name]: fileData }));
            },
          }).then(() => {
            startProjectPolling(300);
            setPolling(true);
          });
        }
      },
    },
  );

  function upload(selectedFiles: File[]): void {
    selectedFiles.forEach((file): void => {
      setFilesToUpload((fs) => ({ ...fs, [file.name]: { file, progress: 0 } }));
      createFileUploadURI({
        variables: {
          input: {
            fileName: file.name,
            projectId: project.id,
          },
        },
      });
    });
  }

  useEffect(() => {
    if (polling) {
      const projectFilenames = files.map((file: T.File) => file.name);
      Object.keys(filesToUpload).forEach((filename) => {
        if (
          filesToUpload[filename].progress === 100
          && projectFilenames.includes(filename)
        ) {
          setFilesToUpload((fs) => {
            const newFs = { ...fs };
            delete newFs[filename];
            return newFs;
          });
        }
      });

      if (Object.keys(filesToUpload).length === 0) {
        stopProjectPolling();
        setPolling(false);
      }
    }
  });

  return (
    <Grid container className={classes.wrapper}>
      <Grid item className={classes.files} xs={12}>
        <DropZone handleFiles={upload} disabled={!canWrite}>
          {canWrite && (
            <div className={classes.buttonWrapper}>
              <label htmlFor="contained-button-file">
                <input
                  accept="*"
                  multiple
                  className={classes.input}
                  onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                    const files = Array.from(event.target.files || []);
                    upload(files);
                  }}
                  id="contained-button-file"
                  type="file"
                />
                <Button
                  startIcon={<UploadIcon />}
                  component="span"
                  className={classes.button}
                >
                  {mobile
                    ? 'Upload'
                    : 'Drag and drop files or click here to upload'}
                </Button>
              </label>
            </div>
          )}
          <List dense className={classes.list}>
            {files.map((file) => (
              <FilesItem
                project={currentProject}
                key={file.name}
                file={file}
                refetchProject={refetchProject}
                canDelete={canWrite}
                setError={setError}
              />
            ))}
            {Object.keys(filesToUpload).map((fileName) => (
              <FilesItemUploading
                value={filesToUpload[fileName].progress}
                key={fileName}
                fileName={fileName}
              />
            ))}
          </List>
        </DropZone>
      </Grid>
    </Grid>
  );
}
