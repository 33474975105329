import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Theme,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Close as CloseIcon, Storage as ProjectIcon } from "@material-ui/icons";
import { useMutation } from "@apollo/client";
import React, {
  useEffect, useState, FormEvent, ReactElement
} from "react";
import { Contact, Field } from "..";
import * as Q from "../../gql";
import * as T from "../../types";
import * as U from "../../utils";
import { Colors } from "../../styles";

// based on this example: https://tinyurl.com/y2qa9oku
const useStyles = makeStyles((theme: Theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  closeIcon: {
    position: "absolute",
    right: theme.spacing(1)
  },
  dialog: {
    padding: theme.spacing(2),
    height: "auto",
    display: "flex",
    flexDirection: "column"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 2, 0, 2)
  },
  projectIcon: {
    color: Colors.PURPLE,
    margin: theme.spacing(0, 2, 0, 0)
  },
  submit: {
    margin: theme.spacing(0, 0, 2, 0),
    textTransform: "none"
  },
  title: {
    display: "flex",
    alignItems: "center"
  }
}));

interface Props {
  open: boolean;
  userData: T.UserData | null | undefined;
  setError: T.SetState<string | undefined>;
  onClose: () => void;
}

export default function ProjectCreator(props: Props): ReactElement {
  const {
    open, userData, setError, onClose
  } = props;
  const classes = useStyles();
  const [input, setInput] = useState<T.CreateProjectInput>({
    name: "",
    contactMethod: T.ContactMethod.EMAIL,
    contact: "",
    description: "",
    isOpen: false,
    userRoles: {
      admin: [],
      editor: [],
      viewer: []
    },
    groupRoles: {
      admin: [],
      editor: [],
      viewer: []
    }
  });

  const helperText = "Only letters, numbers, and [-_ ]";

  const [createProject] = useMutation<T.CreateProject>(Q.createProject, {
    onError: (e) => U.checkApolloError(e, setError),
    onCompleted({ createProject: res }) {
      U.checkMutationError(res, setError);
    },
    refetchQueries: [{ query: Q.effectiveProjectRoles }]
  });

  function reset(): void {
    const resetInput: T.CreateProjectInput = {
      name: "",
      contactMethod: T.ContactMethod.EMAIL,
      contact: "",
      description: "",
      isOpen: false,
      userRoles: {
        admin: [],
        editor: [],
        viewer: []
      },
      groupRoles: {
        admin: [],
        editor: [],
        viewer: []
      }
    };
    if (userData?.username) {
      resetInput.userRoles!.admin = [userData.username];
      resetInput.contactMethod = userData.contactMethod;
      resetInput.contact = userData.contact;
    }
    setInput(resetInput);
  }

  useEffect(() => {
    reset();
  }, [userData]);

  const nameLabel = U.nameLabel(
    "Project",
    input.name,
    U.isValidProjectName,
    "Only letters, numbers, and [-_ ]"
  );

  function onSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    createProject({ variables: { input } });
    onClose();
    reset();
  }

  function hasAdmin(roles: { admin: string[] }): boolean {
    return roles.admin.length > 0;
  }

  const canSave = U.isValidProjectName(input.name)
    && U.isValidContact(input.contactMethod!, input.contact!)
    && (hasAdmin(input.userRoles!) || hasAdmin(input.groupRoles!));

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle disableTypography className={classes.title}>
        <ProjectIcon className={classes.projectIcon} fontSize="large" />
        <Typography variant="h6">Create Project</Typography>
        <IconButton onClick={onClose} className={classes.closeIcon}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <form noValidate onSubmit={onSubmit} className={classes.form}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Field
                name="projectName"
                label={nameLabel}
                value={input.name}
                required
                setValue={(name): void => setInput((v) => ({ ...v, name }))}
                isValid={(n): boolean => U.isValidProjectName(n)}
                helperText={helperText}
              />
            </Grid>
            <Grid item>
              <Contact
                contactMethod={input.contactMethod!}
                setContactMethod={(contactMethod: T.ContactMethod): void => {
                  setInput((v) => ({ ...v, contactMethod }));
                }}
                contact={input.contact!}
                setContact={(contact: string): void => {
                  setInput((v) => ({ ...v, contact }));
                }}
              />
            </Grid>
            <Grid item>
              <Field
                name="description"
                label="Description"
                value={input.description || ""}
                setValue={(description): void => setInput((v) => {
                  const i = { ...v };
                  i.description = description;
                  return i;
                })}
                isValid={(): boolean => true}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                fullWidth
                className={classes.submit}
                disabled={!canSave}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
