import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
      },
    },
  },
  typography: {
    fontFamily: [
      'Source Sans Pro',
      'sans-serif',
    ].join(',')
  },
  palette: {
    primary: {
      main: '#007C92', // Stanford Lagunita
    },
    secondary: {
      light: '#006CB8', // Stanford Blue, used for links
      main: '#544948', // Stanford Stone
    },
    success: {
      main: '#175e54', // Stanford Palo Alto
    },
    background: {
      default: '#fff',
    },
    text: {
      disabled: '#000',
    },
  },
});

export default theme;
