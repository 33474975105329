import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import React, { useEffect, useState, ReactElement } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AlertDialog, Loading, ProjectEditor } from '..';
import * as Q from '../../gql';
import * as T from '../../types';
import * as U from '../../utils';

export default function ProjectDetailPage(): ReactElement {
  const { pathname } = useLocation();
  const history = useHistory();
  const projectId = pathname.split('/').pop();
  const [error, setError] = useState<string | undefined>(undefined);
  const { data: qUsers } = U.useQ<T.Users>(Q.users, setError);
  const { data: qGroupNames } = U.useQ<T.GroupNames>(Q.groupNames, setError);
  const { data: qUser } = U.useQ<T.User>(Q.user, setError);
  const {
    loading: projectLoading,
    error: projectError,
    data: projectData,
  } = useQuery(Q.node, {
    variables: { id: projectId },
  });

  const users = qUsers?.users?.edges.map((e) => e?.node).map((d) => ({
    id: d!.id,
    username: d!.username,
    displayName: d!.displayName
  }));

  const groups = qGroupNames?.groupNames?.map((d) => ({
    id: d,
    username: d,
    displayName: d,
    name: d
  }));

  useEffect(() => {
    if (!U.isLoggedIn()) {
      history.push(T.Route.LOGIN);
    }
  });

  if (projectLoading) {
    return <Loading />;
  }

  if (projectError) {
    /* eslint max-len: 0 */
    const unauthorizedRegex = /unauthorized\sto\sview\sfield\s'.*'\son\s'Project'/;

    if (unauthorizedRegex.test(projectError.message)) {
      return (
        <>
          <Dialog open BackdropProps={{ invisible: true }}>
            <DialogTitle>Unauthorized</DialogTitle>
            <DialogContent>
              <DialogContentText>
                You don&apos;t have permission to view this project.
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={() => {
                  history.push(T.Route.MY_PROJECTS);
                }}
                color="primary"
                variant="outlined"
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  }

  const project = projectData.node;
  const userRole = project?.effectiveUserRoles?.edges.filter(
    (edge: any) => edge?.node?.user?.id === qUser?.user?.id,
  )[0];
  const projectRole = userRole?.node?.role;

  return (
    <>
      <AlertDialog error={error} clear={(): void => setError(undefined)} />
      <ProjectEditor
        open
        project={project}
        projectRole={projectRole}
        users={users || []}
        groups={groups || []}
        setError={setError}
        onClose={() => {
          history.push(T.Route.MY_PROJECTS); // else go to my projects
        }}
      />
    </>
  );
}
