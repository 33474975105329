import React from 'react';
import { createSvgIcon } from '@material-ui/core/utils';

export const ToolsIcon = createSvgIcon(
  <path d="m 21.183232,23.127914 -7.747868,-7.747867 1.759476,-1.759476 7.747868,7.747867 z m -17.934308,0 -1.759476,-1.759476 8.95172,-8.95172 L 7.1382916,9.1138419 6.4283279,9.8238061 5.070136,8.4656149 V 11.089395 L 4.3293039,11.830227 0.56340806,8.0643305 1.3042401,7.3234984 H 3.9588879 L 2.477224,5.8418343 6.5209319,1.7981264 Q 7.0456882,1.2733705 7.6630476,1.0881624 8.2804081,0.90295442 9.0212404,0.90295442 q 0.7408316,0 1.3581916,0.26237798 0.61736,0.2623781 1.142116,0.787134 L 8.2186716,5.2553424 9.7003362,6.7370061 8.9595035,7.4778383 12.169775,10.688111 15.935672,6.9222141 Q 15.688727,6.5209298 15.549823,5.9961739 15.410916,5.4714184 15.410916,4.8849264 q 0,-1.636004 1.188418,-2.824422 1.188418,-1.18841799 2.824421,-1.18841799 0.463021,0 0.787135,0.0926031 0.324114,0.0926042 0.540189,0.24694399 L 18.1273,3.8354134 20.4424,6.1505121 23.06618,3.5267333 q 0.15434,0.2469442 0.262378,0.6019261 0.108034,0.354982 0.108034,0.818002 0,1.6360033 -1.188419,2.8244214 -1.188418,1.188419 -2.824421,1.188419 -0.555624,0 -0.956908,-0.077165 -0.401284,-0.077166 -0.740833,-0.2315115 z" />,
  'Tools',
);

export const CollabIcon = createSvgIcon(
  <path d="m 9.3709368,16.523119 q 0.8763542,0 1.4700132,-0.607794 0.59366,-0.607794 0.59366,-1.455879 0,-0.848084 -0.59366,-1.45588 -0.593659,-0.607793 -1.4417445,-0.607793 -0.8763542,0 -1.4841482,0.607793 -0.6077944,0.607796 -0.6077944,1.45588 0,0.848085 0.6077944,1.455879 0.607794,0.607794 1.4558795,0.607794 z m 2.6290622,-5.286396 q 0.848086,0 1.45588,-0.593659 0.607795,-0.59366 0.607795,-1.4700139 0,-0.8480855 -0.607795,-1.4558795 -0.607794,-0.6077942 -1.45588,-0.6077942 -0.848084,0 -1.455878,0.6077942 -0.607795,0.607794 -0.607795,1.4558795 0,0.8763539 0.607795,1.4700139 0.607794,0.593659 1.455878,0.593659 z m 2.629064,5.286396 q 0.848085,0 1.455879,-0.607794 0.607794,-0.607794 0.607794,-1.455879 0,-0.848084 -0.607794,-1.45588 -0.607794,-0.607793 -1.455879,-0.607793 -0.848084,0 -1.45588,0.607793 -0.607794,0.607796 -0.607794,1.45588 0,0.848085 0.607794,1.455879 0.607796,0.607794 1.45588,0.607794 z M 11.999999,23.3078 q -2.3180983,0 -4.3817717,-0.890489 Q 5.5545538,21.526822 4.013866,19.986134 2.4731782,18.445446 1.5826889,16.381772 0.69219963,14.318099 0.69219963,11.999999 q 0,-2.3180983 0.89048927,-4.3817717 Q 2.4731782,5.5545538 4.013866,4.013866 5.5545538,2.4731782 7.6182273,1.5826889 9.6819007,0.69219963 11.999999,0.69219963 q 2.3181,0 4.381773,0.89048927 2.063674,0.8904893 3.604362,2.4311771 1.540688,1.5406878 2.431177,3.6043613 0.890489,2.0636734 0.890489,4.3817717 0,2.3181 -0.890489,4.381773 -0.890489,2.063674 -2.431177,3.604362 -1.540688,1.540688 -3.604362,2.431177 -2.063673,0.890489 -4.381773,0.890489 z m 0,-11.307801 q 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 0,0 z m 0,9.611631 q 3.957731,0 6.784681,-2.82695 2.82695,-2.82695 2.82695,-6.784681 0,-3.9577296 -2.82695,-6.7846792 -2.82695,-2.8269501 -6.784681,-2.8269501 -3.9577296,0 -6.7846792,2.8269501 -2.8269501,2.8269496 -2.8269501,6.7846792 0,3.957731 2.8269501,6.784681 2.8269496,2.82695 6.7846792,2.82695 z" />,
  'Collaborations',
);
