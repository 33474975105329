import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement } from 'react';

const useStyles = makeStyles(() => ({
  loadingScreen: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: '100vh',
    marginTop: 90,
    marginLeft: 30,
  },
}));

export default function Loading(): ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.loadingScreen}>
      <CircularProgress color="primary" />
    </div>
  );
}
