import {
  Grid, Link, Theme, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement } from 'react';

import {
  Email as EmailIcon,
  Phone as PhoneIcon,
  Twitter as TwitterIcon,
} from '@material-ui/icons';
import { Colors } from '../../styles';

const useStyles = makeStyles((theme: Theme) => ({
  contactUs: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid grey',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    position: 'fixed',
    top: 'auto',
    bottom: 0,
    backgroundColor: theme.palette.background.default,
  },
  bln: {
    marginRight: theme.spacing(1),
    color: Colors.CARDINAL,
  },
  hed: {
    marginRight: theme.spacing(1),
  },
  icon: {
    position: 'relative',
    top: theme.spacing(0.5),
    width: theme.typography.h6.fontSize,
    height: theme.typography.h6.fontSize,
    marginRight: theme.spacing(1),
    color: theme.palette.grey[600],
  },
  link: {
    color: theme.palette.secondary.light,
  },
}));

export default function ContactUs(): ReactElement {
  const classes = useStyles();

  return (
    <Grid
      container
      component="main"
      alignItems="center"
      className={classes.contactUs}
      justifyContent="center"
    >
      <Grid item>
        <Typography variant="subtitle2" className={classes.bln}>
          Big Local News
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" className={classes.hed}>
          450 Jane Stanford Way, Building 120, Stanford, CA 94305
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" className={classes.hed}>
          <EmailIcon className={classes.icon} />
          <Link
            target="_top"
            rel="noopener noreferrer"
            href="mailto:biglocalnews@stanford.edu"
            className={classes.link}
          >
            biglocalnews@stanford.edu
          </Link>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" className={classes.hed}>
          <PhoneIcon className={classes.icon} />
          650.498.2469
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" className={classes.hed}>
          <TwitterIcon className={classes.icon} />
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/biglocalnews"
            className={classes.link}
          >
            @BigLocalNews
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
}
