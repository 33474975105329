import {
  Grid, MenuItem, Select, makeStyles,
} from '@material-ui/core';
import React, { ChangeEvent, ReactElement } from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Email } from '..';
import * as T from '../../types';
import * as U from '../../utils';

interface Props {
  contactMethod: T.ContactMethod;
  setContactMethod: (method: T.ContactMethod) => void;
  contact: string;
  setContact: (value: string) => void;
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'row',
  },
  select: {
    minWidth: 120,
  },
  selectWrap: {
    flexGrow: 0,
  },
  textField: {
    flexGrow: 1,
  },
  field: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Contact(props: Props): ReactElement {
  const classes = useStyles();
  const {
    contactMethod,
    setContactMethod,
    contact,
    setContact,
    disabled,
  } = props;

  function handleChangeMethod(
    e: ChangeEvent<{ name?: string; value: unknown }>,
  ): void {
    setContactMethod(e.target.value as T.ContactMethod);
    setContact('');
  }

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item className={classes.selectWrap}>
        <Select
          value={contactMethod}
          onChange={handleChangeMethod}
          disabled={disabled}
          variant="outlined"
          className={classes.select}
        >
          {Object.keys(T.ContactMethod).map((k) => (
            <MenuItem value={(T.ContactMethod as any)[k]} key={k}>
              {k.toLowerCase()}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item className={classes.textField}>
        {contactMethod === T.ContactMethod.EMAIL ? (
          <Email disabled={disabled} value={contact} setValue={setContact} />
        ) : (
          <MuiPhoneNumber
            error={contact !== '' && !U.isValidPhone(contact)}
            fullWidth
            value={contact}
            variant="outlined"
            disabled={disabled}
            defaultCountry="us"
            onChange={(v: string): void => setContact(v)}
            disableDropdown
          />
        )}
      </Grid>
    </Grid>
  );
}
