/* eslint @typescript-eslint/no-use-before-define: 0 */
/* eslint no-use-before-define: 0 */
import {
  ApolloError,
  useQuery,
  useApolloClient,
  QueryHookOptions,
  QueryResult
} from "@apollo/client";
import { DocumentNode } from "graphql";
import { PhoneNumberUtil } from "google-libphonenumber";
import * as T from "../types";

export function capitalize(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function fileNameFromUri(uri: string): string {
  const path = new URL(uri).pathname;
  const pathComponents = path.split("/");
  return pathComponents[pathComponents.length - 1];
}

export function htmlEscape(unsafe: string): string {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

export function getCookie(name: string): string | null {
  return (
    document.cookie
      ?.split("; ")
      .find((row) => row.startsWith(`${name}=`))
      ?.split("=")[1] || null
  );
}

export function isLoggedIn(): boolean {
  return getCookie("remember_token") !== null;
}
export function isMyProject(
  project: T.ProjectData | T.OpenProjectData
): project is T.ProjectData {
  return (project as T.ProjectData).files !== undefined;
}

export function isValidShortName(v: string): boolean {
  return /^[a-zA-Z][a-zA-Z0-9_]{2,49}$/.test(v);
}

export function isValidTag(v: string): boolean {
  return /^[a-z][a-z0-9_-]{2,20}$/.test(v);
}

export function isValidLegalName(v: string): boolean {
  return /^[a-zA-Z- .]+$/.test(v);
}

export function isValidContact(
  method: T.ContactMethod,
  contact: string
): boolean {
  return method === T.ContactMethod.EMAIL
    ? isValidEmail(contact)
    : isValidPhone(contact);
}

export function isValidEmail(v: string): boolean {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(v);
}

export function isValidPhone(v: string): boolean {
  const util = PhoneNumberUtil.getInstance();
  try {
    const n = util.parseAndKeepRawInput(v);
    if (!util.isValidNumber(n)) {
      return false;
    }
  } catch (err) {
    return false;
  }
  return true;
}

export function isValidProjectName(v: string): boolean {
  return /^[a-zA-Z][a-zA-Z0-9-_ ]{2,49}$/.test(v);
}

// SOURCE: https://tinyurl.com/yylqm4ut
export function isValidUrl(v: string): boolean {
  const pattern = new RegExp(
    "^((ft|htt)ps?:\\/\\/)?"
      + "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|"
      + "((\\d{1,3}\\.){3}\\d{1,3}))"
      + "(\\:\\d+)?"
      + "(\\/[-a-z\\d%@_.~+&:]*)*"
      + "(\\?[;&a-z\\d%@_.,~+&:=-]*)?"
      + "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return pattern.test(v);
}

export function checkApolloError(
  e: ApolloError,
  setError: T.SetState<string | undefined>
): void {
  if (e.graphQLErrors) {
    setError(e.graphQLErrors.map((ge) => ge.message).join());
  }
  if (e.networkError) {
    // If it's a 401 error, kill the cookie and redirect to the homepage
    if (e.networkError.message.includes("status code 401")) {
      setError(
        "You authentication has expired. Please clear your browser cookies and log in again."
      );
    } else {
      // Otherwise log the erro
      setError(e.networkError.message);
    }
  }
}

export function checkMutationError(
  res: { err: string | null } | null | undefined,
  setError: T.SetState<string | undefined>
): void {
  if (res?.err) {
    setError(res.err);
  }
}

export function useIsCached<T>(q: DocumentNode): boolean {
  const client = useApolloClient();
  const response = client.readQuery<T>({ query: q });

  return response != null;
}

export function useQ<T>(
  q: DocumentNode,
  setError: T.SetState<string | undefined>,
  opts?: QueryHookOptions
): QueryResult<T> {
  const query = useQuery<T, QueryHookOptions>(q, {
    fetchPolicy: "network-only",
    errorPolicy: "all",
    onError: (e) => checkApolloError(e, setError),
    ...opts
  });
  return query;
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function nameLabel(
  defaultLabel: string,
  name: string,
  isValid: (s: string) => boolean,
  help: string
): string {
  if (name.length > 0 && name.length < 3) {
    return "Must be at least 3 characters";
  }
  if (name.length > 0 && !isValid(name)) {
    return help;
  }
  return `${defaultLabel} Name`;
}

// this is included in a <script> tag in template.html
declare function gtag(event: any, action: any, options: any): void;
export function track(
  category: T.TrackingCategory,
  action: T.TrackingAction,
  value: string
): void {
  gtag("event", action, {
    event_label: value,
    event_category: category
  });
}

export function websiteLabel(w: string): string {
  if (w.length === 0) {
    return "Website";
  }
  if (!isValidUrl(w)) {
    return "Invalid URL";
  }
  return "Website";
}
