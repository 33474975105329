import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import React, {
  ReactElement, Suspense,
} from 'react';
import { InMemoryCache } from '@apollo/client/cache';
import {
  ApolloClient, ApolloProvider, createHttpLink,
} from '@apollo/client';
import {
  Redirect, HashRouter, Route, Switch,
} from 'react-router-dom';
import theme from '../../styles';
import {
  Login,
  ManageKeys,
  MyGroups,
  MyProjects,
  Navigation,
  OpenProjects,
  PrivateRoute,
  ProjectDetailPage,
} from '..';
import '../../styles/fonts.css';
import * as T from '../../types';

declare const SERVER: string;

// lazy-load since it is heavy and only used by developers
/* eslint max-len: 0 */
function LazyAPIConsole(): ReactElement {
  const APIConsole = React.lazy(() => import(/* webpackChunkName: "develop" */ '../APIConsole'));
  return (
    <Suspense fallback={<div />}>
      <APIConsole />
    </Suspense>
  );
}

export default function App(): ReactElement {
  const cache = new InMemoryCache();
  const link = createHttpLink({
    uri: `${SERVER}/graphql`,
    credentials: 'include',
  });
  const client = new ApolloClient({
    cache,
    link,
  });
  return (
    <HashRouter>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Navigation>
            <Switch>
              <PrivateRoute
                exact
                path={T.Route.MY_PROJECTS}
                component={MyProjects}
              />
              <Route path={T.Route.LOGIN} component={Login} />
              <PrivateRoute path={T.Route.MANAGE_KEYS} component={ManageKeys} />
              <PrivateRoute path={T.Route.MY_GROUPS} component={MyGroups} />
              <Route path={T.Route.OPEN_PROJECTS} component={OpenProjects} />
              <Route path="/project">
                <PrivateRoute
                  path="/:projectId"
                  component={ProjectDetailPage}
                />
              </Route>
              <PrivateRoute
                path={T.Route.API_CONSOLE}
                component={LazyAPIConsole}
              />
              <Route path="*">
                <Redirect to={T.Route.MY_PROJECTS} />
              </Route>
            </Switch>
          </Navigation>
        </ThemeProvider>
      </ApolloProvider>
    </HashRouter>
  );
}
