import {
  Grid, Link, Theme, Typography,
} from '@material-ui/core';
import {
  Settings as EditIcon,
  Email as EmailIcon,
  Language as WebsiteIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import React, { ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: 0,
    width: 'auto',
  },
  hed: {
    color: theme.palette.primary.main,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  links: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 'auto',
  },
  linkWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 1,
    alignItems: 'center',
    marginRight: theme.spacing(2),
    color: theme.palette.secondary.main,
  },
  link: {
    color: theme.palette.secondary.light,
  },
}));

interface TitleSectionProps {
  label: string;
  description: string;
  email?: string;
  website?: string;
  editor?: boolean;
}
export default function TitleSection(props: TitleSectionProps): ReactElement {
  const {
    label, description, website, email, editor,
  } = props;
  const classes = useStyles();

  return (
    <Grid item container className={classes.root} direction="column">
      <Grid item>
        <Typography variant="h4">{label}</Typography>
      </Grid>
      <Grid
        item
        container
        className={classes.links}
        direction="row"
        wrap="nowrap"
        justifyContent="flex-start"
        spacing={2}
      >
        {website && (
          <Grid item className={classes.linkWrapper}>
            <WebsiteIcon fontSize="small" className={classes.icon} />
            <Link
              href={website}
              target="_blank"
              rel="noopener"
              className={classes.link}
            >
              {website}
            </Link>
          </Grid>
        )}
        {email && (
          <Grid item className={classes.linkWrapper}>
            <EmailIcon fontSize="small" className={classes.icon} />
            <Link href={`mailto:${email}`} className={classes.link}>
              {email}
            </Link>
          </Grid>
        )}
        {editor && (
          <Grid item className={classes.linkWrapper}>
            <EditIcon fontSize="small" className={classes.icon} />
          </Grid>
        )}
      </Grid>
      <Grid item>
        <Typography variant="body1">{description}</Typography>
      </Grid>
    </Grid>
  );
}
