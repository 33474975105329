import {
  AppBar,
  IconButton,
  Collapse,
  Divider,
  Drawer,
  Grid,
  Hidden,
  List,
  Theme,
  Toolbar
} from "@material-ui/core";
// import { Alert } from "@material-ui/lab";
import {
  Close as CloseIcon,
  Info as AboutIcon,
  Person as AccountIcon,
  LocalLibrary as DataArchiveIcon,
  Menu as MenuIcon,
  Group as GroupIcon,
  Lock as LogInIcon,
  VpnKey as KeyIcon,
  ExitToApp as LogOutIcon,
  Public as OpenProjectsIcon,
  Settings as SettingsIcon,
  Storage as ProjectIcon,
  Help as SupportIcon,
  Announcement as NewsIcon,
  Lock as MyProjectIcon,
  Star as AffiliatesIcon
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import React, { ReactElement, ReactNode, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { NavItem } from "..";
import logo from "../../imgs/logo.svg";
import * as U from "../../utils";
import * as T from "../../types";
import { ToolsIcon, CollabIcon } from "../../constants/icons";

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    width: "100%"
  },
  alertWrapper: {
    width: "100%",
    flexShrink: 0
  },
  bln: {
    color: "#8c1515"
  },
  content: {
    width: "100%"
  },
  contentWrapper: {},
  closeButton: {
    paddingBottom: 0
  },
  closeIcon: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  menuButton: {
    color: "#8c1515",
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  drawerPaper: {
    width: drawerWidth
  },
  nested: {
    paddingLeft: theme.spacing(3)
  },
  logo: {
    height: "100px",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5)
  }
}));

interface Props {
  children: ReactNode;
}

declare const SERVER: string;

// source: https://material-ui.com/components/drawers/#responsive-drawer
export default function Navigation(props: Props): ReactElement {
  const { children } = props;
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [isMobileOpen, setIsMobileOpen] = useState<boolean>(false);
  const [expandSettings, setExpandSettings] = useState<boolean>(false);
  const [expandData, setExpandData] = useState<boolean>(true);
  const at = (r: T.Route): boolean => location.pathname === r;

  function onClose(): void {
    setIsMobileOpen(false);
  }

  const drawer = (
    <>
      <Hidden smUp implementation="css">
        <div className={classes.closeIcon}>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </Hidden>
      <img src={logo} alt="Big Local News" className={classes.logo} />
      <Divider />
      <List>
        {!U.isLoggedIn() && (
          <NavItem
            key="Log In"
            label="Log In"
            selected={at(T.Route.LOGIN)}
            onClick={() => (window.location.href = `${SERVER}/accounts/login/squarelet`)}
            icon={<LogInIcon />}
          />
        )}
        <>
          <NavItem
            key="Data"
            label="Data"
            onClick={(): void => setExpandData((v) => !v)}
            icon={<ProjectIcon />}
            expandable={U.isLoggedIn()}
            expanded={expandData}
          />
          {U.isLoggedIn() && (
            <Collapse in={expandData} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className={classes.nested}>
                <NavItem
                  key="My Projects"
                  label="My Projects"
                  subNav
                  selected={at(T.Route.MY_PROJECTS)}
                  onClick={(): void => history.push(T.Route.MY_PROJECTS)}
                  icon={<MyProjectIcon color="inherit" />}
                />
                <NavItem
                  key="Open Projects"
                  label="Open Projects"
                  subNav
                  selected={at(T.Route.OPEN_PROJECTS)}
                  onClick={(): void => history.push(T.Route.OPEN_PROJECTS)}
                  icon={<OpenProjectsIcon />}
                />
              </List>
            </Collapse>
          )}
        </>
        <NavItem
          key="Tools"
          label="Tools"
          onClick={() => (window.location.href = "https://biglocalnews.org/content/tools/")}
          icon={<ToolsIcon />}
        />
        <NavItem
          key="Collaborations"
          label="Collaborations"
          onClick={() => (window.location.href = "https://biglocalnews.org/content/collaborations/")}
          icon={<CollabIcon />}
        />
        <NavItem
          key="Affiliates"
          label="Affiliates"
          onClick={() => (window.location.href = "https://biglocalnews.org/content/affiliates/")}
          icon={<AffiliatesIcon />}
        />
        <NavItem
          key="News"
          label="News"
          onClick={() => (window.location.href = "https://biglocalnews.org/content/news/")}
          icon={<NewsIcon />}
        />
        <NavItem
          key="Archive"
          label="Archive"
          onClick={() => (window.location.href = "https://biglocalnews.org/content/archive/")}
          icon={<DataArchiveIcon />}
        />
        <NavItem
          key="Support"
          label="Support"
          onClick={() => (window.location.href = "https://biglocalnews.org/content/support/")}
          icon={<SupportIcon />}
        />
        <NavItem
          key="About"
          label="About"
          onClick={() => (window.location.href = "https://biglocalnews.org/content/about/")}
          icon={<AboutIcon />}
          dividerAfter={U.isLoggedIn()}
        />
        {U.isLoggedIn() && (
          <>
            <NavItem
              key="Settings"
              label="Settings"
              onClick={(): void => setExpandSettings((v) => !v)}
              icon={<SettingsIcon />}
              expandable
              expanded={expandSettings}
            />
            <Collapse in={expandSettings} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className={classes.nested}>
                <NavItem
                  subNav
                  key="Profile"
                  label="Profile"
                  icon={<AccountIcon />}
                  onClick={() => (window.location.href = `${SERVER}/accounts/edit/squarelet`)}
                />
                <NavItem
                  subNav
                  key="Groups"
                  label="Groups"
                  selected={at(T.Route.MY_GROUPS)}
                  onClick={(): void => history.push(T.Route.MY_GROUPS)}
                  icon={<GroupIcon />}
                />
                <NavItem
                  subNav
                  key="Manage API Keys"
                  label="API Keys"
                  selected={at(T.Route.MANAGE_KEYS)}
                  icon={<KeyIcon />}
                  onClick={(): void => history.push(T.Route.MANAGE_KEYS)}
                />
              </List>
            </Collapse>
          </>
        )}
        {U.isLoggedIn() && (
          <NavItem
            key="Log Out"
            label="Log Out"
            onClick={() => (window.location.href = `${SERVER}/accounts/logout/`)}
            icon={<LogOutIcon />}
          />
        )}
      </List>
    </>
  );

  return (
    <div className={classes.root}>
      <nav className={classes.drawer} aria-label="navigation">
        <Hidden smUp implementation="css">
          <AppBar color="inherit" position="fixed">
            <Toolbar>
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={(): void => setIsMobileOpen(true)}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="temporary"
            anchor="left"
            open={isMobileOpen}
            onClose={(): void => setIsMobileOpen(false)}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            <>{drawer}</>
          </Drawer>
        </Hidden>
      </nav>
      <Grid container direction="column" className={classes.contentWrapper}>
        { /* <Grid item>
          <Alert severity="error">
            {'Big Local News will be inaccessible on Saturday, April 15 for a short period of time starting at 1 p.m. EST. Click '}
            <Link href="https://www.muckrock.com/news/archives/2023/apr/12/downtime-april-15-2023/" target="_blank" rel="noreferrer">here</Link>
            {' for more information.'}
          </Alert>
          </Grid> */}
        <Grid
          item
          container
          className={classes.content}
          justifyContent="center"
        >
          {children}
        </Grid>
      </Grid>
    </div>
  );
}
