import {
  Button,
  Grid,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement } from 'react';
import { Colors } from '../../styles';

// based on this example: https://tinyurl.com/yy5tgrbw
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
  },
  copy: {
    paddingLeft: 30,
    paddingTop: 20,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  heroText: {},
  blnTitle: {
    fontFamily: "'Roboto Slab', serif",
    fontWeight: 700,
    color: Colors.CARDINAL,
    fontSize: '5.8em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.7em',
      marginTop: '50px',
      marginBottom: '5px',
    },
  },
  blnText: {
    color: theme.palette.grey[600],
    fontSize: '3.5em',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5em',
    },
  },
  buttonWrapper: {
    marginTop: 20,
  },
  loginButton: {
    marginRight: 20,
    fontSize: '1.33em',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1em',
      marginRight: 10,
    },
  },
}));

declare const SERVER: string;

export default function Login(): ReactElement {
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        component="main"
        className={classes.root}
        justifyContent="flex-end"
        alignContent="flex-start"
        direction="row"
      >
        <Grid container sm={12} className={classes.copy}>
          <Grid item sm={12} className={classes.heroText}>
            <Typography variant="h1" className={classes.blnTitle}>
              Big Local News
            </Typography>
            <Typography className={classes.blnText}>
              Empowering journalists with data, tools and collaborations
            </Typography>
          </Grid>
          <Grid
            item
            container
            className={classes.buttonWrapper}
            sm={12}
          >
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                className={classes.loginButton}
                onClick={() => window.location.href = `${SERVER}/accounts/login/squarelet`}
              >
                Log in
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                className={classes.loginButton}
                onClick={() => window.location.href = `${SERVER}/accounts/signup/squarelet`}
              >
                Sign up
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                className={classes.loginButton}
                onClick={() => window.location.href = `https://biglocalnews.org/content/about/`}
              >
                About
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
