import {
  FormControl,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  NativeSelect,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Delete as DeleteIcon } from '@material-ui/icons';
import React, { ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  role: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  text: {
    marginRight: theme.spacing(6),
  },
}));

export interface Props {
  disabled: boolean;
  name: string;
  role: string;
  roleOptions: string[];
  setRole: (role: string) => void;
  onDelete: () => void;
}

export default function RolesItem(props: Props): ReactElement {
  const {
    name, role, roleOptions, setRole, onDelete, disabled,
  } = props;
  const classes = useStyles();
  return (
    <ListItem key={name} className={classes.item}>
      <ListItemIcon>
        <FormControl className={classes.role}>
          <NativeSelect
            disabled={disabled}
            value={role}
            onChange={(event): void => setRole(event.target.value)}
            name="role"
            disableUnderline
          >
            {roleOptions.map((r) => (
              <option key={r} value={r}>
                {r}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
      </ListItemIcon>
      <ListItemText primary={name} className={classes.text} />
      {!disabled && (
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="remove" onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
