import {
  Card as CardComponent,
  CardActionArea,
  CardContent,
  Divider,
  Grid,
  Theme,
  Typography,
} from '@material-ui/core';
import { Group as GroupIcon, Person as UserIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement } from 'react';
import * as T from '../../types';
import { Colors } from '../../styles';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: 250,
    height: 200,
  },
  cardContent: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    padding: 0,
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  description: {
    marginBottom: 2,
    paddingTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: 105,
  },
  groupIconLarge: {
    color: Colors.GOLD,
  },
  hed: {
    paddingBottom: 0,
    paddingLeft: theme.spacing(1),
  },
  largeIcon: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 5,
    marginBottom: 5,
    fontSize: 80,
  },
  openIcon: {
    paddingRight: theme.spacing(1),
    color: 'rgb(0,0,0,0.5)',
  },
  projectIconLarge: {
    color: Colors.PURPLE,
  },
  role: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1, 0, 1),
    flexGrow: 1,
  },
  roleText: {
    textTransform: 'capitalize',
    opacity: 0.7,
    color: 'rgb(0,0,0,0.7)',
  },
  subhedIcon: {
    marginRight: 3,
  },
  subhedText: {},
  subhedItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 0,
  },
  subhedWrapper: {
    paddingLeft: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    color: 'rgb(0,0,0,0.7)',
    marginBottom: 2,
    opacity: 0.7,
  },
}));

interface Props {
  card: T.ProjectData | T.GroupData;
  role: string;
  onClick: () => void;
}

export default function Card(props: Props): ReactElement {
  const { card, role, onClick } = props;
  const { name } = card;
  const classes = useStyles();
  const users = card.userRoles?.edges.length || 1;
  let desc = card.description;
  if (desc && (desc.length > 100 || desc.length === 0)) {
    desc = `${desc.slice(0, 100).trim()}...`;
  }
  return (
    <CardComponent raised elevation={4} className={classes.card}>
      <CardActionArea onClick={onClick}>
        <CardContent className={classes.cardContent}>
          <Grid container className={classes.hed}>
            <Typography variant="h6" noWrap>
              {name}
            </Typography>
          </Grid>
          <Grid container className={classes.subhedWrapper} spacing={2}>
            <Grid item className={classes.subhedItem}>
              <UserIcon fontSize="small" className={classes.subhedIcon} />
              <Typography variant="subtitle2">{`Members: ${users}`}</Typography>
            </Grid>
          </Grid>
          <Divider />
          {desc ? (
            <Grid container className={classes.description}>
              <Typography variant="body2">{desc}</Typography>
            </Grid>
          ) : (
            <Grid container className={classes.largeIcon}>
              <Grid item className={classes.groupIconLarge}>
                <GroupIcon fontSize="inherit" color="inherit" />
              </Grid>
            </Grid>
          )}
          <Grid container direction="row" alignItems="center">
            <Grid item container className={classes.role} xs={6}>
              <Typography variant="subtitle2" className={classes.roleText}>
                {role.toLowerCase()}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </CardComponent>
  );
}
