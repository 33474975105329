import {
  Grid,
  Chip,
  Badge,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  ExpandMore,
  Help as HelpIcon,
  RemoveCircle as RemoveIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement, useState } from 'react';

import * as T from '../../types';
import { Colors } from '../../styles';

interface Props {
  projects: (T.ProjectEdge | T.OpenProjectEdge | null)[];
  searchResults: string[];
  setSearchResults: T.SetState<string[]>;
  noResults: boolean;
  getTagNames: (proj: any) => string[];
}

const useStyles = makeStyles((theme: Theme) => ({
  deleteIcon: {
    color: Colors.HYPERLINK,
  },
  filteredDeleteIcon: {
    color: theme.palette.common.white,
  },
  filteredTag: {
    backgroundColor: Colors.HYPERLINK,
    color: theme.palette.common.white,
  },
  root: {
    margin: '0.25em 0.25em 1.5em 0.25em',
  },
  accordion: {
    borderRadius: '2px',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 13%), 0 3px 6px rgba(0, 0, 0, 10%)',
    '&:hover': {
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 26%), 0 3px 6px rgba(0, 0, 0, 20%)',
    },
  },
  tag: {
    color: Colors.HYPERLINK,
    borderColor: Colors.HYPERLINK,
  },
  tagWrapper: {
    margin: theme.spacing(0, 2, 2, 0),
  },
  tagsWrapper: {
    paddingLeft: theme.spacing(2),
  },
  title: {
    color: theme.palette.grey[900],
    marginRight: theme.spacing(1),
  },
  titleWrapper: {
    opacity: 0.7,
  },
}));

export default function TagsFilter(props: Props): ReactElement {
  const {
    projects,
    searchResults,
    setSearchResults,
    noResults,
    getTagNames,
  } = props;

  const [expanded, setExpanded] = useState<boolean>(false);

  const classes = useStyles();

  const displayedProjects = projects.filter((p) => {
    if (searchResults.length > 0) return searchResults.includes(p!.node!.id);
    return true;
  });

  const tagsList: { name: string; count: number }[] = [];

  const findProjectsWithTag = (tag: string): string[] => {
    const matches = displayedProjects
      .filter((proj) => getTagNames(proj).includes(tag))
      .map((proj) => proj!.node!.id);

    return matches;
  };

  const includeTag = (tagName: string): void => {
    setSearchResults(findProjectsWithTag(tagName));
  };

  const excludeTag = (tagName: string): void => {
    setSearchResults(
      displayedProjects
        .filter((p) => !findProjectsWithTag(tagName).includes(p!.node!.id))
        .map((p) => p!.node!.id),
    );
  };

  displayedProjects.forEach((proj) => {
    getTagNames(proj).forEach((tagName) => {
      const tagMatch = tagsList.find((pTag) => pTag.name === tagName);
      if (!tagMatch) {
        tagsList.push({ name: tagName, count: 1 });
      } else {
        tagMatch.count += 1;
      }
    });
  });

  tagsList.sort((a, b) => {
    if (a.count > b.count) return -1;
    if (b.count > a.count) return 1;
    if (a.name > b.name) return 1;
    if (b.name > a.name) return -1;
    return 0;
  });

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded} className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          onClick={(): void => {
            setExpanded(!expanded);
          }}
          className={classes.titleWrapper}
        >
          <Typography variant="subtitle2" className={classes.title}>
            Filter by tag
          </Typography>
          <Tooltip
            arrow
            placement="right"
            title="Click a tag to display projects with that tag.
            To hide projects with a given tag, click its minus sign."
          >
            <HelpIcon fontSize="small" color="disabled" />
          </Tooltip>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item container className={classes.tagsWrapper}>
            {noResults || tagsList.length === 0 ? (
              <Typography variant="caption">No tags found.</Typography>
            ) : (
              tagsList.map(
                (tag): ReactElement => {
                  const tagInAllProjs = displayedProjects.length === tag.count;
                  return (
                    <Grid item key={tag.name} className={classes.tagWrapper}>
                      <Badge
                        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                        badgeContent={tag.count}
                        color="primary"
                      >
                        <Chip
                          label={tag.name}
                          classes={{
                            root: tagInAllProjs
                              ? classes.filteredTag
                              : classes.tag,
                            deleteIcon: tagInAllProjs
                              ? classes.filteredDeleteIcon
                              : classes.deleteIcon,
                          }}
                          variant="outlined"
                          size="small"
                          clickable={!tagInAllProjs}
                          onClick={(): void => includeTag(tag.name)}
                          deleteIcon={<RemoveIcon />}
                          onDelete={
                            displayedProjects.length !== 1
                            && displayedProjects.length !== tag.count
                              ? (): void => excludeTag(tag.name)
                              : undefined
                          }
                        />
                      </Badge>
                    </Grid>
                  );
                },
              )
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
